<template>
  <div class="text-center">
    <span v-if="readOnly" style="font-size: 2.25rem; line-height: 1" v-html="displayRating(value)"></span>
    <label v-else>
      <select :value="rating" @input="handleChange" :disabled="disabled" class="custom-select custom-select mx-3" v-bind:class="{'text-white bg-success': parseInt(rating) === maxRating}"
              style="width: 80px">
        <option></option>
        <option :key="`rating-display-${i-1}`" v-for="i in (maxRating + 1)" :value="i-1">{{displayRating(i-1)}}</option>
      </select>
    </label>
  </div>
</template>

<script>
import { studentTracking } from '@/http'

export default {
  name: 'SkillRating',
  props: {
    value: {},
    skillId: {
      type: String,
      required: false
    },
    personId: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minRating: {
      type: Number,
      default: 0
    },
    maxRating: {
      type: Number,
      default: 3
    },
    staticStyle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      emoji: false,
      progress: true,
      localRating: undefined
    }
  },
  computed: {
    readOnly () {
      return (this.$store.getters['auth/userType'] !== 'User' ||
        !this.personId ||
        !this.skillId)
    },
    rating () {
      return this.localRating || this.value
    }
  },
  methods: {
    handleChange (event) {
      this.localRating = event.target.value
      studentTracking.post(`subscription-agenda/${this.$route.params.agenda_id}/persons/${this.personId}/skill-ratings`, {
        SkillID: this.skillId,
        Rating: this.localRating
      })
        .then(() => {
          this.$emit('change', this.localRating)
        })
        .catch(error => {
          this.showErrorToast(error)
          this.localRating = undefined
        })
    },
    displayRating (rating) {
      if (!this.readOnly) {
        return this.symbolMapping(rating)
      } else if (this.emoji) {
        return this.emojiMapping(rating)
      } else if (this.progress) {
        return this.progressMapping(rating)
      }
    },
    emojiMapping (rating) {
      rating = Math.round(rating)

      let map = {}

      switch (this.maxRating) {
        case 1:
          map = {
            0: '🙂',
            1: '😁'
          }
          break
        case 2:
          map = {
            0: '🙂',
            1: '😊',
            2: '😁'
          }
          break
        case 3:
        default:
          map = {
            0: '😶',
            1: '🙂',
            2: '😊',
            3: '😁'
          }
      }

      return map[rating]
    },
    symbolMapping (rating) {
      if (isNaN(rating)) {
        return null
      }

      rating = Math.round(rating)

      let map = {}

      switch (this.maxRating) {
        case 1:
          map = {
            0: '—',
            1: '＋'
          }
          break
        case 2:
          map = {
            0: '—',
            1: '◯',
            2: '＋'
          }
          break
        case 3:
        default:
          map = {
            0: '—',
            1: '◯',
            2: '◯/＋',
            3: '＋'
          }
      }

      return map[rating]
    },
    progressMapping (rating) {
      let pct

      if (isNaN(parseInt(rating))) {
        // No rating has been provided yet
        pct = 0
      } else {
        const offset = 20
        const step = (100 - offset) / (this.maxRating - this.minRating)
        pct = offset + (rating * step)
      }

      let bg = 'bg-danger'

      if (pct > 30) {
        bg = 'bg-warning'
      }
      if (pct > 55) {
        bg = 'bg-info'
      }
      if (pct > 80) {
        bg = 'bg-success'
      }
      if (this.staticStyle !== '') {
        bg = this.staticStyle
      }

      return `<div class="progress"><div class="progress-bar ${bg}" role="progressbar" style="width: ${pct}%"></div></div>`
    }
  }
}
</script>
