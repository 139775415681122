import store, { SET_APP_LOADING } from '@/store'

export const appLoaded = new Promise(resolve => {
  store.dispatch('loadApp')
  store.subscribe(mutation => {
    if (mutation.type === SET_APP_LOADING && mutation.payload === false) {
      resolve()
    }
  })
})
