import { protectedApi } from '@/http'

export const SET_WAREHOUSES = 'SET_WAREHOUSES'
export const SET_VIEW_WAREHOUSES = 'SET_VIEW_WAREHOUSES'

export default {
  namespaced: true,
  state: {
    warehouses: [],
    viewWarehouses: []
  },
  actions: {
    fetch ({ commit, dispatch }) {
      return protectedApi.get('warehouses', { params: { StudentTrackingYN: 1 } })
        .then(response => {
          commit(SET_WAREHOUSES, response.data.items || [])
          dispatch('setViewWarehouses', (response.data.items || []).map(item => item.WarehouseID))
        })
    },
    setViewWarehouses ({ commit, dispatch, rootGetters }, warehouses) {
      commit(SET_VIEW_WAREHOUSES, warehouses)
    }
  },
  mutations: {
    [SET_WAREHOUSES] (state, warehouses) {
      state.warehouses = warehouses
    },
    [SET_VIEW_WAREHOUSES] (state, warehouses) {
      state.viewWarehouses = warehouses
    }
  },
  getters: {
    all: state => state.warehouses
  }
}
