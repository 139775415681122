import { publicApi } from '@/http'

export const SET_MENU = 'SET_MENU'

export default {
  namespaced: true,
  state: {
    menu: []
  },
  actions: {
    fetch ({ commit }) {
      return publicApi.get('menu', { params: { singleLevel: 'LVS', fullUrl: true } })
        .then(menu => {
          return commit(SET_MENU, menu.data.LVS || [])
        })
    }
  },
  mutations: {
    [SET_MENU] (state, menu) {
      state.menu = menu
    }
  }
}
