import store from '@/store'

const locale = store.getters.getLocale || 'nl-NL'

const formatDateTime = (date, options = {}) => {
  if (!date) return date

  if (typeof date === 'string') {
    date = new Date(date)
  }

  return date.toLocaleString(locale, options)
}

export default formatDateTime
