<!-- TODO; multiple missing translations in this file! -->

<template>
  <tr class="student" :class="{ 'selected': selected }">
    <th>
      <b-form-checkbox @change="$emit('toggleSelection', student.PersonID)" v-model="selected" />
    </th>

    <th scope="row" class="bg-light border-bottom-0 min-width-125">
      <a @click.prevent class="text-nowrap d-block" tabindex="0" :id="_id(`student-row-popover-${student.PersonID}`)"
        :class="{ 'text-dark': student.PresentYN, 'text-muted': !student.PresentYN, 'd-block': skills.length > 0, 'd-inline-block mr-3': skills.length === 0 }">
        <font-awesome-icon fixed-width class="mr-2" :icon="isFuture ? 'circle-dashed' : 'circle'"
          :class="{ 'text-success': present && !isFuture, 'text-danger': !present && !isFuture, 'text-muted': isFuture }" />
        <span :style="student.CancelYN ? 'text-decoration:line-through' : ''">{{ student.Name }}</span>
        <span v-if="student.ActivityGroupTotal > 1" class="ml-2 badge badge-secondary"
          v-b-tooltip.hover="`In ${student.ActivityGroupTotal} lesgroepen`">{{ student.ActivityGroupTotal }}</span>
        <font-awesome-icon icon="check" class="text-success ml-2" v-b-tooltip.hover="`Mag afzwemmen`"
          v-if="student.ReadyForFinishedActivityGroupYN || student.OnFinishedListYN" />
        <font-awesome-icon icon="triangle-exclamation" class="text-warning ml-2"
          v-b-tooltip.hover="`Afgemeld voor les ${absence}`" v-if="student.CancelYN" />
        <font-awesome-icon :icon="['fad', 'calendar-check']" class="text-success ml-2" v-b-tooltip.hover="'Inhaalles'"
          v-if="rescheduled" />
      </a>

      <small class="d-inline-block text-muted">
        <abbr v-b-tooltip.hover="'Aanw. huidig niveau'">{{ parseInt(getSumPresent()) }}</abbr> /
        <abbr v-b-tooltip.hover="'Afw. huidig niveau'">{{ parseInt(getSumAbsent()) }}</abbr>
        <abbr v-if="getAttendancePercentage()"> ({{ getAttendancePercentage() }}%)</abbr>

        <span v-if="teaser" v-b-tooltip.hover="teaser" class="text-warning ml-2">
          <font-awesome-icon icon="bell" fixed-width />
        </span>

        <!--        show icon if student is currently in other group-->
        <span v-if="newGroup" v-b-tooltip.hover="`Verplaatst naar ${newGroup.Name}`" class="text-info ml-2">
          <font-awesome-icon icon="angle-right" fixed-width />
        </span>

        <!--        show icon if student moves to new group in future-->
        <span v-else-if="futureMoveList" v-b-tooltip.hover="`${futureMoveList}`" class="text-info ml-2">
          <font-awesome-icon icon="angles-right" fixed-width />
        </span>

        <span v-if="typeof student.ValidAccessYN !== 'undefined' && !student.ValidAccessYN && !rescheduled"
          v-b-tooltip.hover="'Geen geldige leskaart'" class="text-danger ml-2">
          <font-awesome-icon icon="ban" fixed-width />
        </span>
      </small>

      <b-popover :target="_id(`student-row-popover-${student.PersonID}`)" triggers="focus" ref="student-popover">
        <b-button @click="togglePresence" block v-if="!isFuture"
          :variant="present ? 'outline-danger' : 'outline-success'" size="sm">
          <font-awesome-icon :icon="present ? 'user-slash' : 'user-plus'" fixed-width size="lg" />
          {{ present ? 'Afwezig' : 'Aanwezig' }}
        </b-button>

        <b-button block variant="outline-info" size="sm" @click="show.modal.comment = true">
          <font-awesome-icon icon="pencil" fixed-width size="lg" />
          {{ $t("context.remark") }}
        </b-button>

        <b-button block variant="secondary" size="sm" :to="{ name: 'student', params: { student_id: student.PersonID } }">
          <font-awesome-icon :icon="['fad', 'eye']" fixed-width size="lg" />
          {{ $t("actions.view") }}
        </b-button>
      </b-popover>
      <small class="clearfix"></small>
      <small class="d-inline-block text-muted" v-if="student.SkillCategory.TimeDescription
    && !isCustomerPortal
    && student.SkillCategory.TimeDescription !== '0'
    ">
        <font-awesome-icon fixed-width class="mr-2" :icon="'circle'" :class="{
    'text-success': parseInt(getSumPresent()) + this.presenceModifier <= parseInt(student.SkillCategory.TimeDescription),
    'text-danger': parseInt(getSumPresent()) + this.presenceModifier > parseInt(student.SkillCategory.TimeDescription)
  }" />
        <abbr v-b-tooltip.hover="'Insch. lessen nodig'">+/- {{ student.SkillCategory.TimeDescription }} lessen</abbr>
      </small>
    </th>

    <!-- SKILL RATINGS -->
    <td class="text-center" :key="skill.SkillID" v-for="skill in skills">
      <skill-rating :disabled="disabled || student.CancelYN" :person-id="student.PersonID" :skill-id="skill.SkillID"
        :value="skill.Rating" :max-rating="_meta.LVS__SKILL__MAX_RATING" />
    </td>

    <!-- MODALS -->
    <b-modal lazy v-model="show.modal.absence" @ok="togglePresence" cancel-variant="outline-danger"
      :cancel-title="`${$t('actions.cancel')}`" ok-variant="success" :ok-title="`${$t('context.accept')}`">
      <span slot="modal-title"><font-awesome-icon icon="user-slash" fixed-width /> {{ $t("context.submit_absence")
        }}</span>

      <b-form>
        <b-form-group :description="`${$t('context.reason_is_optional')}`"
          label="`${$t('context.give_reason_of_absence')}`">
          <b-form-textarea :value="absenceReason" ref="modal-absence-textarea" rows="3"
            :placeholder="`${$t('context.reason')}`"></b-form-textarea>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal lazy v-model="show.modal.comment" @ok="addComment" cancel-variant="outline-secondary"
      :cancel-title="`${$t('actions.close')}`" ok-variant="success" :ok-title="`${$t('actions.save')}`">
      <span slot="modal-title"><font-awesome-icon icon="pencil" fixed-width /> {{ $t("context.submit_remark") }}</span>

      <b-form @submit.prevent="">
        <b-form-group :description="`${$t('context.reason_is_internal')}`">
          <label slot="label">{{ $t("context.enter_remark_below") }} <strong>{{ student.Name }}</strong> in.</label>
          <b-form-textarea v-model="newComment" ref="modal-comment-textarea" rows="3"
            :placeholder="`${$t('context.reason')}`"></b-form-textarea>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal lazy v-model="show.modal.graduate" :title="`${$t('context.read_to_finish_course')}`"
      cancel-variant="outline-danger" :cancel-title="`${$t('actions.cancel')}`" ok-variant="success"
      :ok-title="`${$t('context.accept')}`">
      <p>{{ $t("context.are_you_sure_that") }} <strong>{{ student.Name }}</strong> {{ $t("context.mark_as_ready") }}
      </p>
    </b-modal>
  </tr>
</template>

<script>
import SkillRating from './SkillRating'
import { studentTracking } from '@/http'
import moment from 'moment'

function formatDate (date, format) {
  return moment(date).format((format))
}

export default {
  name: 'StudentMatrixRow',
  components: { SkillRating },
  props: {
    student: {
      type: Object,
      required: true,
      default () {
        return {
          items: [],
          PersonID: '',
          Name: '',
          Teaser: '',
          PresentYN: false,
          AbsenceReason: ''
        }
      }
    },
    selectedIndexes: {
      type: Array,
      required: false
    },
    _meta: {
      type: Object,
      default () {
        return {
          LVS_EMAIL: null,
          LVS__SKILL__MAX_RATING: 3
        }
      }
    },
    disabled: Boolean
  },
  data () {
    return {
      skillValues: [null, 0, 1, 2, 3],
      absenceReason: '',
      presenceModifier: 0, // -1 for absent, 1 for present
      newComment: '',
      show: {
        modal: {
          absence: false,
          comment: false,
          graduate: false
        }
      },
      selected: false
    }
  },

  computed: {
    currentGroup () {
      return this.$store.getters['groups/all'].find(group => {
        return group.SubscriptionAgendaID === this.$route.params.agenda_id
      }) || {}
    },
    newGroup () {
      let newGroup = null
      this.student.ActivityGroup.forEach((group) => {
        if (group.ParentActivityGroupID === this.currentGroup.ActivityGroupID) {
          newGroup = group
        }
      })
      return newGroup
    },
    futureMoveList () {
      if (this.student.person_activitygroup_moves) {
        if (this.student.person_activitygroup_moves.length > 0) {
          let moveString = ''
          this.student.person_activitygroup_moves.forEach(item => {
            if ((new Date(item.to.StartDate)).getTime() - (new Date()).getTime() > 1000 * 3600 * 24) {
              moveString += this.$t('context.moved_since') + formatDate(item.to.StartDate, 'ddd DD MMM') + this.$t('context.to_spaced') + item.to['ActivityGroup.Name'] + '. '
            }
          })
          return moveString
        }
      }
      return false
    },
    rescheduled () {
      return this.student.RescheduledYN
    },
    skills () {
      return this.student.items
    },
    present () {
      return this.student.PresentYN
    },
    isFuture () {
      return (new Date(this.currentGroup.StartDate)).getTime() - (new Date()).getTime() > 1000 * 3600 * 24
    },
    absence () {
      if (this.present === false && this.student.AbsenceReason && this.student.AbsenceReason !== '') {
        return ' : ' + this.student.AbsenceReason
      }

      return ''
    },
    teaser () {
      return this.student.Teaser || null
    }
  },

  methods: {
    togglePresence (e, present) {
      const { PresentYN, PersonID } = this.student
      let { presenceModifier } = this
      const presentValue = present !== undefined ? present : !PresentYN

      if (PresentYN !== presentValue) {
        this.student.PresentYN = presentValue
        this.presenceModifier += presentValue ? 1 : -1
      }

      return studentTracking.put(`subscription-agenda/${this.$route.params.agenda_id}/persons/${PersonID}`, {
        PresentYN: this.student.PresentYN
      }).then(() => {
        return this.student
      }).catch(error => {
        this.student.PresentYN = PresentYN
        this.presenceModifier = presenceModifier
        this.showErrorToast(error)
      })
    },
    addComment (evt) {
      return studentTracking.post(this.$router.resolve({
        name: 'student',
        params: { student_id: this.student.PersonID }
      }).route.path + '/reviews', {
        Review: this.newComment
      }).catch(() => {
        evt.preventDefault()
      }).then(() => {
        this.newComment = ''
      })
    },
    getSumPresent () {
      const dynamicKey = this.student.SkillCategoryID
      if (this.student.Attendance[dynamicKey]) {
        return this.student.Attendance[dynamicKey].SumPresent
      }
      return 0 // Handle the case when the dynamic key is not found
    },
    getSumAbsent () {
      const dynamicKey = this.student.SkillCategoryID
      if (this.student.Attendance[dynamicKey]) {
        return this.student.Attendance[dynamicKey].SumAbsent
      }
      return 0 // Handle the case when the dynamic key is not found
    },
    getAttendancePercentage () {
      const dynamicKey = this.student.SkillCategoryID
      if (!this.student.Attendance[dynamicKey]) {
        return null
      }

      let sumPresent = parseInt(this.student.Attendance[dynamicKey].SumPresent)
      let sumAbsent = parseInt(this.student.Attendance[dynamicKey].SumAbsent)

      let percentage = 0
      // prevent dividing by zero resulting in NaN
      if (sumAbsent + sumPresent !== 0) {
        percentage = (100 * sumPresent) / (sumAbsent + sumPresent)
      }
      return Math.round(percentage)
    }
  },

  watch: {
    selectedIndexes (newVal) {
      this.selected = newVal.includes(this.student.PersonID)
    }
  }
}
</script>
