<template>
  <div class="accordion mt-3" role="tablist">
    <b-card v-for="group in renderGroups" :key="group.UID" no-body bg-variant="contrast">
      <b-card-header role="tab" header-tag="header" v-b-toggle="'accordion-' + group.UID">{{ group.GroupName }}:
        {{ group.SkillCategoryName }}
        <span class="when-opened float-right">
         <font-awesome-icon icon="chevron-down"/>
        </span>
        <span class="when-closed float-right">
         <font-awesome-icon icon="chevron-right"/>
        </span>
        <div v-if="group.ProgressTrackingYN && group.Skill.MaxRating > 0">
          <p class="mb-1">{{ $t("context.progress") }}:</p>
          <skill-rating class="mb-2" :max-rating="group.Skill.MaxRating" :value="group.Skill.TotalRating"></skill-rating>
        </div>
        <b-badge variant="danger" class="mr-1" v-if="!group.ValidAccessYN && group.ProgressTrackingYN">
          <font-awesome-icon size="lg" icon="ban" fixed-width/>
          {{ $t("context.lesson_card") }}
        </b-badge>
        <b-badge variant="success" v-if="group.ReadyForFinishedActivityGroupYN" class="mr-1">
          <font-awesome-icon :icon="['fad', 'person-swimming']" size="lg" fixed-width/>
          {{ $t("context.finish_course_allowed") }}
        </b-badge>
      </b-card-header>

      <b-collapse :id="'accordion-' + group.UID" visble :accordion="'my-accordion-' + group.PersonID" role="tabpanel">
        <b-card-body>
          <b-alert :show="group.ShowAlert" variant="danger">{{ group.Alert }}</b-alert>
          <b-media no-body class="mb-1">
            <b-media-aside class="py-1">
              <font-awesome-icon icon="location-dot" fixed-width/>
            </b-media-aside>
            <b-media-body>
              <span>{{ group.WarehouseName }}</span>
            </b-media-body>
          </b-media>
          <b-media no-body class="mb-1" v-if="group.EmployeeName">
            <b-media-aside class="py-1">
              <font-awesome-icon icon="whistle" fixed-width/>
            </b-media-aside>
            <b-media-body>
              <span>{{ group.EmployeeName }}</span>
              <span v-if="group.SecondaryEmployeeName">{{ group.SecondaryEmployeeName }}</span>
            </b-media-body>
          </b-media>
          <b-media no-body class="mb-1" v-if="group.ProgressTrackingYN">
            <b-media-aside class="py-1">
              <font-awesome-icon icon="calendar" fixed-width/>
            </b-media-aside>
            <b-media-body>
              <span>{{ $t("context.planned_moments") }}:</span>
              <ul class="mb-0 pl-4" v-if="group.PlannedMoments.length">
                <li class="py-1" :key="value.id" v-for="(value) in group.PlannedMoments" :class="{'iscancelled': value.isCancelled || value.isSubscriptionCancelled }">{{ value.date }}
                  <div v-if="value.isCancelled" class="badge badge-danger ml-2">{{ $t("context.logged_out") }}</div>
                  <div v-if="value.isSubscriptionCancelled" class="badge badge-danger ml-2">{{ $t("context.cancelled") }}</div>
                </li>
              </ul>
              <ul v-else class="mb-0 pl-4">
                <li class="py-1">{{ $t("context.no_moment_planned") }}</li>
              </ul>

              <span v-if="renderCatchUp.length">{{ $t("context.make_up_lessons") }}:</span>
              <ul class="mb-0 pl-4" v-if="renderCatchUp.length">
                <li class="py-1" v-for="lesson in renderCatchUp" :key="'catchuplesson_'+lesson.UID">
                  <span v-for="(moment, index) in lesson.PlannedMoments" :key="'catchuplessondata_'+moment.id">
                    {{ moment.date }}<span v-if="index !== lesson.PlannedMoments.length - 1">, </span><span v-else-if="lesson.PlannedMoments.length > 1"> en </span>
                  </span>
                </li>
              </ul>
            </b-media-body>
          </b-media>

          <b-media no-body class="mb-1" v-if="group.MostRecentlyPresentMoment">
            <b-media-aside class="py-1">
              <font-awesome-icon icon="clipboard-list-check" fixed-width/>
            </b-media-aside>
            <b-media-body>
              <span>{{ $t("context.last_present_at") }} {{ group.MostRecentlyPresentMoment }}</span>
            </b-media-body>
          </b-media>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import SkillRating from '@/components/SkillRating'
import formatDateTime from '@/util/dateTime/formatDateTime'
import formatDate from '@/util/dateTime/formatDate'

export default {
  name: 'CourseList',
  components: { SkillRating },
  props: {
    insights: Array,
    groups: Array,
    personId: String,
    attendance: Array,
    catchUpGroups: Array
  },
  computed: {
    renderGroups () {
      let renderGroups = []

      this.insights.forEach(group => {
        renderGroups.push({
          'PersonID': this.personId,
          'UID': this.getUniqueIdentifier(group),
          'GroupName': this.getNameOfGroup(group),
          'EmployeeName': this.getEmployeeName(group),
          'SecondaryEmployeeName': this.getSecondaryEmployeeName(group),
          'Skill': group.Skill,
          'ProgressTrackingYN': !this.getProgressTrackingYN(group),
          'SkillCategoryName': this.getSkillCategoryNameForGroup(group),
          'SkillMaxRating': this.getSkillCategoryNameForGroup(group),
          'WarehouseName': this.getWarehouseNameOfGroup(group),
          'AvailabilityStrings': this.getAvailabilityStringsForGroup(group),
          'PlannedMoments': this.getPlannedMomentsForGroup(group),
          'MostRecentlyPresentMoment': this.getLastMomentForGroup(group),
          'Alert': this.getAlertInfoForGroup(group),
          'ShowAlert': this.getShowAlertForGroup(group),
          'ValidAccessYN': group.ValidAccessYN,
          'ReadyForFinishedActivityGroupYN': group.ReadyForFinishedActivityGroupYN
        })
      })

      return renderGroups
    },
    renderCatchUp () {
      let renderGroups = []
      this.catchUpGroups.forEach(group => {
        renderGroups.push({
          'PersonID': this.personId,
          'UID': this.getUniqueIdentifier(group),
          'PlannedMoments': this.getPlannedMomentsForGroup(group),
          'WarehouseName': this.getWarehouseNameOfGroup(group)
        })
      })
      return renderGroups
    }
  },
  methods: {
    getUniqueIdentifier (group) {
      return `${this.personId}_${group.ActivityGroupID}`
    },
    getNameOfGroup (group) {
      return group.ActivityGroupName
    },
    getEmployeeName (group) {
      return group.EmployeeName
    },
    getSecondaryEmployeeName (group) {
      return group.SecondaryEmployeeName
    },
    getSkillCategoryNameForGroup (group) {
      return group.Skill.SkillCategoryName
    },
    getWarehouseNameOfGroup (group) {
      return group.WarehouseName
    },
    getAvailabilityStringsForGroup (group) {
      return group.Availability.map(e => {
        let a = {
          'string': '',
          'AvailableYN': e.AvailableYN
        }
        a.string = this.dayOfWeekFromAvailability(e)
        return a
      })
    },
    getPlannedMomentsForGroup (group) {
      return group.PlannedMoments.map(e => {
        return {
          'id': e.Person_SubscriptionAgendaID,
          'date': formatDateTime(e.StartDate, {
            dateStyle: 'long',
            timeStyle: 'short'
          }),
          'isCancelled': e.CancelYN,
          'isSubscriptionCancelled': e.CancelSubscriptionYN
        }
      })
    },
    getLastMomentForGroup (group) {
      if (Object.is(group.MostRecentlyPresentMoment, null)) {
        return ''
      } else {
        return formatDate(
          group.MostRecentlyPresentMoment.EndDate, {
            dateStyle: 'long'
          })
      }
    },
    getAlertInfoForGroup (group) {
      return group.Alert
    },
    getShowAlertForGroup (group) {
      return group.Alert !== ''
    },
    getProgressTrackingYN (group) {
      return group.WaitingListYN || group.FinishedListYN
    },
    dayOfWeekFromAvailability (availability) {
      if (!availability) {
        return this.$t('context.time_unknown')
      }

      // 1970-01-01 is a Sunday (0), so add DayOfWeek to get the correct day name
      try {
        const dateStart = new Date(1970, 1, 1, ...availability.TimeStart.split(':').map(str => parseInt(str)))
        dateStart.setDate(dateStart.getDate() + parseInt(availability.DayOfWeek))
        const dateEnd = new Date(1970, 1, 1, ...availability.TimeEnd.split(':').map(str => parseInt(str)))
        dateEnd.setDate(dateEnd.getDate() + parseInt(availability.DayOfWeek))

        return dateStart.toLocaleString('nl-NL', {
          weekday: 'long',
          hour: 'numeric',
          minute: '2-digit'
        }) + ' - ' +
          dateEnd.toLocaleString('nl-NL', {
            hour: 'numeric',
            minute: '2-digit'
          })
      } catch (e) {
        return this.$t('context.time_unknown')
      }
    }
  }
}
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
li.iscancelled {
  text-decoration: line-through;
}
</style>
