import { protectedApi } from '../http'

export const SET_CONFIG = 'SET_CONFIG'

const flatten = (object, prefix = '') => {
  return Object.keys(object).reduce((prev, element) => {
    return object[element] && typeof object[element] === 'object' && !Array.isArray(element)
      ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
      : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
  }, {})
}

export default {
  namespaced: true,

  state: {
    config: {
      student_tracking: {
        customer: {
          show_waiting_list_sequence: false
        }
      }
    }
  },

  actions: {
    fetchConfig ({ commit, rootGetters }) {
      return protectedApi.get('company/lvs')
        .then(res => {
          let configItems = res.data.items || []

          // Save object key as CompanyID
          if (configItems.length) {
            configItems = configItems.reduce((acc, item) => {
              acc[item.CompanyID] = item
              return acc
            }, {})
          }

          return commit(SET_CONFIG, configItems)
        })
    }
  },

  mutations: {
    [SET_CONFIG] (state, config) {
      state.config = {
        ...state.config,
        ...config
      }
    }
  },

  getters: {
    config: state => state.config,
    flatConfig: state => flatten(state.config)
  }
}
