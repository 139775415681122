import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './auth'
import groups from './groups'
import persons from './persons'
import warehouses from './warehouses'
import employees from './employees'
import menu from './menu'
import banners from './banners'
import standardTexts from './standardTexts'
import config from './config'
import { publicApi } from '@/http'
import i18next from 'i18next'
import moment from 'moment/moment'

Vue.use(Vuex)

// Use sessionStorage for data persistence (different for each tab/window)
const vuexSession = new VuexPersistence({
  storage: sessionStorage,
  key: 'lvs',
  reducer: (state) => ({ groups: state.groups })
})

// Use localStorage for data persistence (even between sessions)
const vuexLocal = new VuexPersistence({
  storage: localStorage,
  key: 'lvs',
  reducer: (state) => {
    const data = {
      preview: false,
      auth: state.auth,
      // warehouses: state.warehouses,
      // employees: state.employees,
      menu: state.menu,
      persons: state.persons
    }

    if (data.auth.status) {
      delete data.auth.status.loggingIn
    }

    return data
  }
})

Vue.mixin({
  computed: {
    isCustomerPortal () {
      return this.$store.getters['auth/userType'] !== 'User'
    }
  }
})

export const SET_APP_LOADING = 'SET_APP_LOADING'
export const SET_APP_OFFLINE = 'SET_APP_OFFLINE'
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING'
export const SET_SW = 'SET_SW'
export const SET_INFO = 'SET_INFO'
export const SET_PREVIEW = 'SET_PREVIEW'

export const SET_LOCALE = 'SET_LOCALE'

const store = new Vuex.Store({
  plugins: [
    vuexSession.plugin,
    vuexLocal.plugin
  ],
  modules: {
    auth,
    groups,
    persons,
    warehouses,
    employees,
    menu,
    banners,
    standardTexts,
    config
  },
  state: {
    appOffline: false,
    appLoading: true,
    pageLoading: false,
    preview: false,
    sw: null,
    info: {},
    locale: localStorage.getItem('locale') || 'nl-NL'
  },
  actions: {
    appOffline ({ commit }) {
      commit(SET_APP_OFFLINE, true)
    },
    appOnline ({ commit }) {
      commit(SET_APP_OFFLINE, false)
    },
    pageLoading ({ commit }) {
      commit(SET_PAGE_LOADING, true)
    },
    pageLoaded ({ commit }) {
      commit(SET_PAGE_LOADING, false)
    },
    showRefreshNotification ({ commit }, worker) {
      commit(SET_SW, worker)
    },
    dismissRefreshNotification ({ commit }) {
      commit(SET_SW, null)
    },
    setPreviewMode ({ commit }, preview) {
      commit(SET_PREVIEW, preview)
    },
    refreshToUpdate ({ commit }) {
      commit(SET_SW, null)
      window.location.reload()
    },
    setLocale ({ commit }, locale) {
      locale = locale.replace(/_/g, '-')

      commit('SET_LOCALE', locale)

      localStorage.setItem('locale', locale)
      moment.locale(locale)
      i18next.changeLanguage(locale)
    },
    restoreLocaleFromStorage ({ commit }) {
      const storedLocale = localStorage.getItem('locale')
      if (storedLocale) {
        commit('SET_LOCALE', storedLocale)
      }
    },
    async fetchInfo ({ commit }) {
      try {
        const { data } = await publicApi.get('info')
        return commit(SET_INFO, data)
      } catch (e) {
      }
    },
    loadApp ({ dispatch, commit, rootGetters }) {
      const promises = []
      promises.push(
        dispatch('fetchInfo')
          .catch(() => {
            dispatch('auth/logout') //
          })
      )

      if (rootGetters['auth/loggedIn']) {
        promises.push(
          dispatch('auth/fetchUser')
            .catch(() => {
              dispatch('auth/logout')
            })
        )
      } else {
        promises.push(
          dispatch('auth/login')
            .catch(() => {
              dispatch('auth/logout')
            })
        )
      }

      Promise.all(promises)
        .finally(() => {
          commit(SET_APP_LOADING, false)
        })
    }
  },
  mutations: {
    [SET_APP_OFFLINE] (state, offline) {
      state.appOffline = offline
    },
    [SET_APP_LOADING] (state, loading) {
      state.appLoading = loading
    },
    [SET_PAGE_LOADING] (state, loading) {
      state.pageLoading = loading
    },
    [SET_SW] (state, worker) {
      state.sw = worker
    },
    [SET_INFO] (state, info) {
      state.info = info
    },
    [SET_LOCALE] (state, locale) {
      state.locale = locale
    },
    [SET_PREVIEW] (state, preview) {
      state.preview = !!preview
    }
  },
  getters: {
    students: state => state.students,
    skills: state => state.skills,
    getLocale: state => state.locale,
    getLanguage: state => state.locale.split('-')[0]
  }
})

moment.locale(store.state.locale)

export default store
