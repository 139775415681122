import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Group from './views/Group.vue'
import Student from './views/Student.vue'
import ChildHome from './views/ChildFriendly/ChildHome.vue' // TODO; move use to own child object and include as ...childRoutes
import StickerBook from './views/ChildFriendly/StickerBook.vue' // TODO; move use to own child object and include as ...childRoutes
import StickerDetail from './views/ChildFriendly/StickerDetail.vue' // TODO; move use to own child object and include as ...childRoutes
import Skill from './views/Skill.vue'
import Login from './views/Login.vue'

import store from './store'
import { appLoaded } from '@/support'

Vue.use(Router)

Vue.mixin({
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
})

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/persons/:student_id',
      name: 'person',
      component: Student
    },
    {
      path: '/child/:student_id',
      name: 'childhome',
      component: ChildHome,
      meta: {
        child_friendly: true
      }
    },
    {
      path: '/child/:student_id/:group_id',
      name: 'stickerbook',
      component: StickerBook,
      meta: {
        child_friendly: true
      }
    },
    {
      path: '/child/:student_id/:group_id/:skill_id',
      name: 'stickerdetail',
      component: StickerDetail,
      meta: {
        child_friendly: true
      }
    },
    {
      path: '/subscription-agenda/:agenda_id',
      name: 'group',
      component: Group
    },
    {
      path: '/subscription-agenda/:agenda_id/persons/:student_id',
      name: 'student',
      component: Student
    },
    {
      path: '/subscription-agenda/:agenda_id/skills/:skill_id',
      name: 'skill',
      component: Skill
    },
    {
      path: '/skills/:skill_id',
      name: 'skill-info',
      component: Skill
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  await store.restored
  await appLoaded

  store.dispatch('pageLoading')

  if (to.query.jwt) {
    document.cookie = `jwt=${to.query.jwt};expires=${Date.now() + 3600 * 24}`
    store.dispatch('auth/login', { redirect: false })
      .then(() => next({ path: to.path }))
      .catch(() => next({ name: 'login' }))
  } else if (to.name !== 'login') {
    if (!store.state.auth.status.loggedIn) {
      next({ name: 'login' }) // If not logged in, redirect to login page
    } else if (!store.getters['auth/userType']) {
      store.dispatch('auth/fetchUser') // If user type is unknown, fetch user before allowing page access
        .then(next)
        .catch(() => next({ name: 'login' })) // If fetching user fails (e.g. expired token), redirect to login page
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  store.dispatch('pageLoaded')
})

export default router
