<template>
  <router-link
    class="text-reset"
    :to="{ name: variant, params: { skill_id: skill.SkillID } }"
  >
    {{ skill.Name }}
    <span class="date" v-if="!!lastUpdated">Laatst gewijzigd:&nbsp;{{ lastUpdated }}</span>
  </router-link>
</template>

<script>
import moment from 'moment' // eslint-disable-line
import { AUTH_TYPE_CUST } from '@/store/auth'
import { has } from 'lodash'

export default {
  name: 'SkillLink',
  props: {
    skill: {
      type: Object,
      required: true
    },
    placement: {
      type: String,
      required: false,
      default: 'right'
    }
  },
  computed: {
    getConfig () {
      return this.$store.getters['config/config']
    },

    variant () {
      return this.$store.getters['auth/userType'] === 'User' ? 'skill' : 'skill-info'
    },

    loginType () {
      return this.$store.getters['auth/authType']
    },

    lastUpdated () {
      // When the company setting is not present/found, or the value is 0 dont show the last updated skill date to parents/caregivers.
      if (this.loginType === AUTH_TYPE_CUST && (!has(this.getConfig, 'LVS__CUSTOMER__SHOW_SKILL_LAST_UPDATE_DATE.Value') || !+this.getConfig.LVS__CUSTOMER__SHOW_SKILL_LAST_UPDATE_DATE.Value)) {
        return false
      }

      if (this.$route.name !== 'student' && this.$route.name !== 'person') {
        return false
      }

      if (!this.skill.Rating && this.skill.rating !== 0) {
        return false
      }

      const date = this.skill.ModifiedDate || this.skill.CreatedDate

      if (date) {
        return moment(date).format('DD-MM-YYYY [om] HH:mm')
      }

      return false
    }
  }
}
</script>

<style scoped>
  .date{
    display: block;
    font-size: 0.8em;
    color: #bbb;
  }
</style>
