<template>
  <div>
    <b-button-toolbar class="home-datepicker-toolbar flex-nowrap justify-content-center">
      <b-dropdown right variant="primary" menu-class="mt-1" class="mx-1">
        <template v-slot:button-content>
          <font-awesome-icon :icon="['fas', 'envelope']" fixed-width />
        </template>

        <b-dropdown-header class="text-uppercase">{{ $t("context.sent_message_to") }}</b-dropdown-header>
        <b-dropdown-item @click="sendMessage(TYPE_DATE, viewDate)" v-if="activeView">
          {{ $t('context.group_descriptor', { descr: messageDateDescriptor }) }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendMessage(TYPE_EMPLOYEE)" v-else-if="activeEmployee">
          {{ $t('context.group_descriptor_1', { name: activeEmployee.Name, descr: messageDateDescriptor }) }}
        </b-dropdown-item>
        <b-dropdown-item @click="sendMessage(TYPE_DEFAULT)" v-else>
          {{ $t('context.group_descriptor_2', { descr: messageDateDescriptor }) }}
        </b-dropdown-item>

        <b-dropdown-item
          :key="warehouse.WarehouseID"
          v-for="warehouse in addressableWarehouses"
          @click="sendMessage(TYPE_WAREHOUSE, viewDate, warehouse)"
        >
          {{ warehouse.Name }} {{ dateIsCurrent ? currentDateDescriptor.toLocaleLowerCase('nl-NL') : $t('context.on') + ` ${viewDateDescriptor}` }}
        </b-dropdown-item>
      </b-dropdown>

      <b-button-group class="dropdowns mx-1" v-if="activeView">
        <b-button v-b-tooltip.hover="showEmpty ? $t('context.hide_empty_groups') : $t('context.show_empty_groups')" variant="primary" @click="$store.dispatch('groups/toggleShowEmpty')">
          <font-awesome-icon :icon="['fad', showEmpty ? 'eye' : 'eye-slash']" fixed-width />
        </b-button>
        <b-dropdown v-if="warehouses.length > 1" right variant="primary" menu-class="mt-1" class="warehouseSelect">
          <template v-slot:button-content>
            <font-awesome-icon fixed-width class="text-warning mr-1" v-if="viewWarehouses.length === 0" icon="triangle-exclamation"/>
            <font-awesome-icon :icon="['fas', 'building']" fixed-width />
          </template>

          <b-dropdown-header class="text-uppercase">{{ $t('entities.warehouse', { count: 2 }) }}</b-dropdown-header>

          <b-dropdown-item
            :key="warehouse.WarehouseID"
            v-for="warehouse in warehouses"
            @click="toggleViewWarehouse(warehouse.WarehouseID)"
          >
            <font-awesome-icon fixed-width class="text-primary" :icon="viewWarehouses.includes(warehouse.WarehouseID) ? 'circle-check' : ['far', 'circle']"/>
            {{ warehouse.Name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>

      <b-button-group class="mx-1">
        <b-button @click="decreaseDate" variant="primary">
          <font-awesome-icon icon="angle-left" fixed-width/>
        </b-button>

        <b-button @click="resetDate" v-bind:class="{active: dateIsCurrent}" :title="currentDateDescriptor" variant="primary" class="text-nowrap">
          <font-awesome-icon fixed-width :icon="['fas', 'rotate-right']"/>
        </b-button>

        <b-button @click="openDatepicker" @blur="closeDatepicker" variant="primary" class="text-nowrap">
          {{ viewDateDescriptor }}
        </b-button>

        <b-button @click="increaseDate" variant="primary">
          <font-awesome-icon icon="angle-right" fixed-width/>
        </b-button>
      </b-button-group>
    </b-button-toolbar>
    <datepicker
      bootstrap-styling
      :language="nl"
      maximum-view="day"
      full-month-name
      monday-first
      calendar-class="mt-2 rounded border-0 overflow-hidden shadow"
      input-class="d-none"
      wrapper-class="text-dark"
      ref="mainDatepicker"
      :value="viewDate"
      @input="setViewDate"/>

    <message-modal :id="_id('modal-group-message')" :ref="_id('modal-group-message')" :reference="messageRef" :type="messageType"/>
  </div>
</template>

<script>
import moment from 'moment'
import { nl } from 'vuejs-datepicker/dist/locale'
import { xor } from 'lodash'
import { mapGetters } from 'vuex'
import MessageModal, { TYPE_DEFAULT, TYPE_EMPLOYEE, TYPE_DATE, TYPE_WAREHOUSE } from '@/components/MessageModal'

export default {
  name: 'DatepickerToolbar',
  components: { MessageModal },
  data () {
    return {
      nl,
      messageType: null,
      messageRef: undefined,
      TYPE_DATE,
      TYPE_DEFAULT,
      TYPE_EMPLOYEE,
      TYPE_WAREHOUSE
    }
  },
  computed: {
    ...mapGetters('groups', ['activeView', 'activeEmployee', 'showEmpty', 'viewDate']),
    warehouses () {
      return this.$store.getters['warehouses/all']
    },
    addressableWarehouses () {
      if (!this.activeView) {
        return []
      }
      return this.$store.getters['warehouses/all']
    },
    viewWarehouses () {
      return this.$store.state.warehouses.viewWarehouses
    },
    messageDateDescriptor () {
      return this.dateIsCurrent
        ? this.currentDateDescriptor.toLocaleLowerCase('nl-NL')
        : this.viewDateDescriptor.toLocaleLowerCase('nl-NL')
    },
    currentDateDescriptor () {
      if (this.activeView) {
        return this.$t('context.today')
      }
      return this.$t('context.this_week')
    },
    viewDateDescriptor () {
      if (this.activeView) {
        return moment(this.viewDate).format('ll')
      }
      return `Week ${moment(this.viewDate).week()}`
    },
    dateIsCurrent () {
      if (this.activeView) {
        return moment(this.viewDate).format('ll') === moment().format('ll')
      }
      return moment(this.viewDate).week() === moment().week()
    },
    dateIncrement () {
      return this.activeView ? 1 : 7
    }
  },
  methods: {
    sendMessage (type, viewDate, warehouse) {
      this.messageType = type
      this.messageRef = warehouse
      // console.log(this.$refs, this._id('modal-group-message'))
      this.$refs[this._id('modal-group-message')].show()
    },
    openDatepicker () {
      this.$refs.mainDatepicker.showCalendar()
    },
    closeDatepicker () {
      this.$refs.mainDatepicker.close()
    },
    decreaseDate () {
      this.setViewDate(new Date(this.viewDate.getTime() - this.dateIncrement * 24 * 3600 * 1000))
    },
    increaseDate () {
      this.setViewDate(new Date(this.viewDate.getTime() + this.dateIncrement * 24 * 3600 * 1000))
    },
    resetDate () {
      this.setViewDate(new Date())
    },
    setViewDate (date) {
      this.closeDatepicker()
      this.$store.dispatch('groups/setViewDate', date)
    },
    setViewWarehouses (warehouses) {
      this.$store.dispatch('warehouses/setViewWarehouses', warehouses)
    },
    toggleViewWarehouse (warehouse) {
      this.$store.dispatch('warehouses/setViewWarehouses', xor(
        this.$store.state.warehouses.viewWarehouses,
        [warehouse]
      ))
    }
  },
  mounted () {
    if (this.warehouses.length === 0) {
      this.$store.dispatch('warehouses/fetch')
    } else if (this.$store.state.warehouses.viewWarehouses.length === 0) {
      this.setViewWarehouses(this.warehouses.map(item => item.WarehouseID))
    }
  }
}
</script>

<style lang="scss">
  .warehouseSelect {
    .custom-control {
      white-space: nowrap;
    }
  }
</style>
