<template>
  <div class="container py-3 py-lg-5">
    <header class="text-contrast mb-4 mb-lg-5">
      <b-button variant="primary" class="mb-3" :to="{name: 'group'}" v-if="!isCustomerPortal">
        <font-awesome-icon icon="angle-left" fixed-width/>
        {{ $t("context.group") }}
      </b-button>

      <b-button variant="primary" class="mb-3" @click.prevent="goBack" v-else>
        <font-awesome-icon icon="angle-left" fixed-width/>
        {{ $t("actions.back") }}
      </b-button>

      <vue-content-loading :speed="1" secondary="#d0d0d0" :height="72" :width="825" style="opacity: 0.35" v-if="showPlaceholder">
        <rect width="400" height="50" x="0" y="10" ry="25" rx="25"></rect>
      </vue-content-loading>
      <div class="row" v-if="!showPlaceholder">
        <div class="col-md-7">
          <h1 class="display-4">{{ skill.Name }}</h1>
        </div>
        <div class="col-md-5 col-lg-5 col-xl-4 ml-auto">
          <b-card class="card-hover" no-body v-if="
            !isCustomerPortal
            && skill.TimeDescription
            && skill.TimeDescription !== '0'
          ">
            <b-card-body>
              <b-card-title class="d-flex align-items-center">
                Extra info
              </b-card-title>
              <div>{{ $t("context.expected_duration") }}: {{skill.TimeDescription}}</div>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </header>

    <section v-if="!isCustomerPortal" class="mb-4 mb-lg-5">
      <vue-content-loading :speed="1" secondary="#d0d0d0" :height="800" :width="1250" style="opacity: 0.35" v-if="showPlaceholder">
        <rect width="1250" height="600" x="0" y="0" ry="5" rx="5"></rect>
      </vue-content-loading>

      <table class="table table-bordered table-hover bg-white rounded overflow-hidden" v-else>
        <thead>
        <tr class="table-light bg-light">
          <th>{{ $t("entities.person", { count: 1 }) }}</th>
          <th>{{ $t("context.skill_control") }}</th>
        </tr>
        </thead>

        <tbody>
        <tr :key="i" v-for="(student, i) in students">
          <td style="vertical-align: middle" :style="student.CancelYN ? 'text-decoration:line-through' : ''"><student-link :name="student.Name" :id="student.PersonID"/></td>
          <td><skill-rating :value="student.Rating" :person-id="student.PersonID" :max-rating="_meta.LVS__SKILL__MAX_RATING" :skill-id="skill.SkillID"/></td>
        </tr>
        </tbody>
      </table>
    </section>

    <b-card no-body v-if="skill.Description">
      <b-card-body v-html="skill.Description"/>
    </b-card>
  </div>
</template>

<script>
import SkillRating from '../components/SkillRating'
import StudentLink from '../components/StudentLink'
import { protectedApi, studentTracking } from '@/http'

export default {
  components: { StudentLink, SkillRating },
  data () {
    return {
      skill: {}
    }
  },
  computed: {
    students () {
      return this.skill.items || []
    },
    _meta () {
      return this.skill._meta
    },
    showPlaceholder () {
      return this.loading
    },
    loading () {
      return Object.values(this.skill).length === 0
    }
  },
  methods: {
    fetchSkill () {
      if (this.$route.name === 'skill-info') {
        protectedApi.get(this.$route.path)
          .then(response => {
            this.skill = response.data
          })
      } else {
        studentTracking.get(this.$route.path)
          .then(response => {
            this.skill = response.data
          })
      }
    }
  },
  mounted () {
    this.fetchSkill()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchSkill()
    })
  },
  beforeRouteLeave (to, from, next) {
    this.skill = {}
    next()
  }
}
</script>
