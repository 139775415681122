<template>
    <div class="col-12 col-lg-6 pb-2 pb-md-3">
        <div class="card card-hover border-0 bg-light h-100">
            <router-link :to="{name: 'group', params: {agenda_id: group.SubscriptionAgendaID}}" class="d-block text-decoration-none">
                <div class="card-body">
                    <b-media>
                        <font-awesome-icon class="text-dark" :icon="['fad', 'person-swimming']" size="3x" slot="aside"/>
                        <font-awesome-icon class="text-info float-right ml-1" :icon="['fad', 'alarm-clock']" size="2x" v-if="current" @click.prevent.stop v-b-tooltip.hover="'Nu bezig'" />
                        <font-awesome-icon class="text-success float-right ml-1" :icon="['fad', 'square-check']" size="2x" v-if="isFinished" @click.prevent.stop v-b-tooltip.hover="'Afgelopen'" />
                        <font-awesome-icon class="text-warning float-right ml-1" icon="triangle-exclamation" size="2x" v-if="group.PersonCount && group.PersonCount === '0' && !isFinished && !current" @click.prevent.stop v-b-tooltip.hover="'Nog niet gevuld'" />
                        <font-awesome-icon class="text-danger float-right ml-1" :icon="['fad', 'circle-exclamation']" size="2x" v-if="group.PersonCount && group.PersonCount === '0' && (isFinished || current)" @click.prevent.stop v-b-tooltip.hover="'Lege groep!'" />
                        <h5 class="mt-0">{{name}}</h5>
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item font-weight-bold">{{warehouseName}}</li>
                            <li class="list-inline-item">&bullet;</li>
                            <li class="list-inline-item">{{dayOfWeek}}</li>
                            <li class="list-inline-item">&bullet;</li>
                            <li class="list-inline-item">{{skillLevel}}</li>
                            <li class="list-inline-item" v-if="group.PersonCount">&bullet;</li>
                            <li class="list-inline-item" v-if="group.PersonCount"><span class="badge badge-pill badge-dark">{{group.PersonCount}}</span></li>
                        </ul>
                    </b-media>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Group',
  props: {
    'group': Object,
    'temp': Boolean
  },
  computed: {
    name () {
      if (this.group.Name) {
        if (this.$store.getters['groups/activeView'] && this.group.EmployeeID && this.group.Employee) {
          if (this.group.SecondaryEmployeeID) {
            return `${this.group.Name} (${this.group.Employee.Name || this.group.EmployeeID} & ${this.group.SecondaryEmployee.Name || this.group.SecondaryEmployeeID})`
          }
          return `${this.group.Name} (${this.group.Employee.Name || this.group.EmployeeID})`
        }

        return this.group.Name
      }

      return this.group.ActivityGroupID
    },
    warehouseName () {
      if (this.group.Warehouse && this.group.Warehouse.Name) {
        return this.group.Warehouse.Name
      }

      return this.group.WarehouseID
    },
    dayOfWeek () {
      const date = new Date(this.group.StartDate)
      return date.toLocaleString('nl-NL', {
        weekday: 'long',
        day: this.temp ? 'numeric' : undefined,
        month: this.temp ? 'long' : undefined,
        hour: 'numeric',
        minute: '2-digit'
      })
    },
    skillLevel () {
      if (this.group.SkillCategory && this.group.SkillCategory.Name) {
        return this.group.SkillCategory.Name
      }
      return `Zwemniveau ${this.group.SkillCategoryID}`
    },
    current () {
      const now = new Date()
      return now < new Date(this.group.EndDate) && now > new Date(this.group.StartDate)
    },
    isFinished () {
      return new Date() > new Date(this.group.EndDate)
    }
  }
}
</script>
