import { studentTracking } from '@/http'

export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_GROUPS = 'SET_GROUPS'
export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const SET_LOADING = 'SET_LOADING'
export const SET_VIEW = 'SET_VIEW'
export const SET_VIEW_DATE = 'SET_VIEW_DATE'
export const TOGGLE_SHOW_EMPTY = 'TOGGLE_SHOW_EMPTY'
export const ADD_GROUP_REFRESH = 'ADD_GROUP_REFRESH'
export const REMOVE_GROUP_REFRESH = 'REMOVE_GROUP_REFRESH'

const orderGroups = (a, b) => {
  const startA = new Date(a.StartDate)
  const startB = new Date(b.StartDate)
  if (startA.getDay() < startB.getDay()) {
    return -1
  }
  if (startA.getDay() > startB.getDay()) {
    return 1
  }
  return 0
}

const defaultState = {
  employee: null,
  loading: false,
  groups: [],
  view: null,
  views: [
    {
      id: 'per-day',
      name: 'Lessen per dag'
    }
  ],
  viewDate: new Date(),
  showEmpty: true,
  needRefresh: []
}

export default {
  namespaced: true,
  state: defaultState,
  actions: {
    clear ({ commit }) {
      commit(CLEAR_STATE)
    },
    fetch ({ commit, state, getters }) {
      commit(SET_LOADING, true)
      return studentTracking.get('subscription-agenda', {
        params: {
          EmployeeID: state.employee ? state.employee.EmployeeID : undefined,
          StartDate: getters.viewDate ? getters.viewDate.toISOString().slice(0, 10) : undefined
        }
      }).then(user => {
        commit(SET_GROUPS, user.data.items || [])
        commit(SET_LOADING, false)
      }).catch(() => {
        commit(SET_GROUPS, [])
        commit(SET_LOADING, false)
      })
    },
    setActiveEmployee ({ commit, dispatch, rootGetters }, employee) {
      commit(SET_EMPLOYEE, rootGetters['employees/all'].find(item => item.EmployeeID === employee.EmployeeID))
      dispatch('fetch')
    },
    setActiveView ({ commit, dispatch, rootGetters }, view) {
      commit(SET_VIEW, view)
      if (!rootGetters['groups/loading']) {
        dispatch('fetch')
      }
    },
    setViewDate ({ commit, dispatch, rootGetters }, date) {
      commit(SET_VIEW_DATE, date)
      if (!rootGetters['groups/loading']) {
        dispatch('fetch')
      }
    },
    toggleShowEmpty ({ commit }) {
      commit(TOGGLE_SHOW_EMPTY)
    },
    markGroupForRefresh ({ commit }, { group }) {
      commit(ADD_GROUP_REFRESH, group)
    },
    markGroupAsRefreshed ({ commit }, { group }) {
      commit(REMOVE_GROUP_REFRESH, group)
    }
  },
  mutations: {
    [CLEAR_STATE] (state) {
      Object.keys(defaultState).forEach(key => {
        state[key] = defaultState[key]
      })
      state.viewDate = new Date()
    },
    [SET_GROUPS] (state, groups) {
      state.groups = groups
    },
    [SET_EMPLOYEE] (state, employee) {
      state.employee = employee
      state.view = null
    },
    [SET_LOADING] (state, loading) {
      state.loading = loading
    },
    [SET_VIEW] (state, view) {
      state.view = view
      state.employee = null
    },
    [SET_VIEW_DATE] (state, date) {
      state.viewDate = date
    },
    [TOGGLE_SHOW_EMPTY] (state) {
      state.showEmpty = !state.showEmpty
    },
    [ADD_GROUP_REFRESH] (state, group) {
      state.needRefresh = [
        ...state.needRefresh,
        group
      ]
    },
    [REMOVE_GROUP_REFRESH] (state, group) {
      state.needRefresh = state.needRefresh.filter(val => val !== group)
    }
  },
  getters: {
    employeeId: (state, getters, rootState, rootGetters) => {
      if (getters.activeEmployee) {
        return getters.activeEmployee.EmployeeID
      }
      return rootGetters['auth/id']
    },
    activeEmployee: (state, getters, rootState, rootGetters) => {
      return rootGetters['auth/isAdmin'] ? state.employee : null
    },
    views: state => state.views,
    activeView: state => state.view,
    viewDate: state => new Date(state.viewDate),
    all: (state, getters) => {
      return state.groups
        .filter(group => getters.showEmpty || typeof group.PersonCount === 'undefined' || group.PersonCount !== '0')
        .sort(orderGroups)
    },
    own: (state, getters) => {
      if (getters.activeView) {
        return getters.all
      }
      return getters.all.filter(group => group.ActivityGroup.EmployeeID === getters.employeeId || group.ActivityGroup.SecondaryEmployeeID === getters.employeeId)
    },
    temp: (state, getters) => {
      if (getters.activeView) {
        return []
      }
      return getters.all.filter(group => group.ActivityGroup.EmployeeID !== getters.employeeId && group.ActivityGroup.SecondaryEmployeeID !== getters.employeeId)
    },
    showEmpty: (state) => {
      return state.showEmpty
    }
  }
}
