<template>
  <!-- Modal Component -->
  <b-modal
    :id="_id('modal-transfer')"
    v-model="show"
    @ok="startTransfer"
    @show="fetchSimilarGroups"
    ok-variant="success"
    :ok-title="$t('context.transfer')"
    cancel-variant="outline-dark"
    :cancel-title="$t('actions.cancel')"
    @hidden="$emit('transferModalClosed')"
    :ok-disabled="transferToGroup === null"
  >
    <span slot="modal-title"><font-awesome-icon icon="angles-right" fixed-width/>{{ $t('context.transfer_student', { count: student || multipleStudents.length === 1 ? 1 : 2 }) }}</span>

    <b-form-group :label="$t('context.transfer_group_student', { count: student || multipleStudents.length === 1 ? 1 : 2 })" :description="!$store.getters['auth/isAdmin'] ? $t('context.transfer_group_student_error') : ''">
      <b-select v-model="transferToGroup" :disabled="transferGroupOptions.length === 0" :options="transferGroupOptions"></b-select>
    </b-form-group>

    <b-form-group :label="$t('context.transfer_which_date')">
      <b-form-datepicker
        locale="nl-NL"
        hide-header
        :min="new Date().toISOString().split('T')[0]"
        :max="maxDate" required today-button
        v-model="transferToGroupStartDate"
        :disabled="transferGroupOptions.length === 0"
        v-bind="{
            labelPrevDecade: $t('datepicker.decade_back'),
            labelPrevYear: $t('datepicker.year_back'),
            labelPrevMonth: $t('datepicker.month_back'),
            labelCurrentMonth: $t('datepicker.this_month'),
            labelNextMonth: $t('datepicker.month_ahead'),
            labelNextYear: $t('datepicker.year_ahead'),
            labelNextDecade: $t('datepicker.decade_ahead'),
            labelToday: $t('common.today'),
            labelTodayButton: $t('datepicker.select_today'),
            labelSelected: $t('datepicker.chosen_date'),
            labelNoDateSelected: $t('datepicker.no_date_chosen'),
            labelCalendar: $t('common.calendar'),
            labelNav: $t('common.navigation'),
            labelHelp: $t('datepicker.use_arrows_to_navigate')
        }"
      />
    </b-form-group>

    <b-checkbox v-model="adoptSkillCategoryFromGroup">{{ $t('context.select_skill_from_new_group') }}</b-checkbox>
  </b-modal>
</template>

<script>
import { studentTracking } from '@/http'

export default {
  name: 'StudentTransferModal',

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    student: {
      type: Object,
      required: false
    },
    // This is an array of students personIds, if we want to transfer multiple students at once (overview page).
    multipleStudents: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data () {
    return {
      show: false,
      transferToGroup: null,
      similarGroups: [],
      loading: false,
      transferring: false,
      transferToGroupStartDate: new Date().toISOString().split('T')[0],
      maxDate: (() => {
        const d = new Date()
        d.setUTCMonth(d.getUTCMonth() + 2)
        return d.toISOString().split('T')[0]
      })(),
      adoptSkillCategoryFromGroup: true
    }
  },

  computed: {
    transferGroupOptions () {
      let options = []

      Object.keys(this.similarGroups).map(key => {
        options.push({
          value: key,
          text: this.similarGroups[key]
        })
      })

      return options.sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) return -1
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1
        return 0
      })
    }
  },

  methods: {
    startTransfer () {
      this.transferring = true

      if (!this.$route.params.agenda_id) {
        console.error('No agenda_id found in route!')
        this.showErrorToast({ message: this.$t('common.error') })
        this.transferring = false
        return
      }

      // We transfer one or multiple students at once, depending omn the multipleStudents prop.
      this.transfer(this.multipleStudents.length ? this.multipleStudents : [this.student])
    },

    transfer (persons) {
      persons.forEach((person, index) => {
        studentTracking.put(`subscription-agenda/${this.$route.params.agenda_id}/persons/${person.PersonID}`, {
          ActivityGroupID: this.transferToGroup,
          StartDate: this.transferToGroupStartDate,
          AdoptSkillCategoryFromGroupYN: this.adoptSkillCategoryFromGroup
        }).then(() => {
          // Check if this is the last in the loop before we redirect and show a toast.
          if ((index + 1) === this.multipleStudents.length || this.student) {
            this.$emit('fetchHistory')
            this.$emit('markGroupForRefresh', { group: this.$route.params.agenda_id })

            this.show = false

            // Show different toast messages depending on the amount of students transferred.
            if (this.multipleStudents.length === 1 || this.student) {
              this.showSuccessToast(this.$t('context.student_succesfull_moved', { 'person': person.Name }))
            } else {
              this.showSuccessToast(this.$t('context.students_succesfull_moved'))
            }

            if (this.$route.name === 'group') {
              this.$store.dispatch('groups/setViewDate', new Date(this.transferToGroupStartDate))
              this.$router.push({ name: 'home' })
            } else {
              this.$router.push({ name: 'group' })
            }
          }
        }).catch(err => {
          this.showErrorToast(err)
        }).finally(() => {
          this.transferring = false
        })
      })
    },

    fetchSimilarGroups () {
      this.loading = true

      studentTracking.get(`subscription-agenda/${this.$route.params.agenda_id || 'current'}/similar`)
        .then(response => {
          this.loading = false
          this.similarGroups = response.data
        })
    }
  },

  watch: {
    // Reset everything.
    showModal (val) {
      this.show = val

      if (val) {
        this.transferToGroup = null
        this.similarGroups = []
        this.transferring = false
        this.adoptSkillCategoryFromGroup = true
      }
    },

    transferring (bool) {
      this.$emit('setTransferring', bool)
    }
  }
}
</script>
