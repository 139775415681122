import { publicApi } from '@/http'

export const ADD_STANDARD_TEXT = 'ADD_STANDARD_TEXT'

export default {
  namespaced: true,
  state: {
    standardtexts: {}
  },
  actions: {
    fetch ({ commit }) {
      const StandardTextArr = [
        'LVSCANCELATION'
      ]

      StandardTextArr.forEach(standardTextID => {
        publicApi.get(`standard-text/${standardTextID}`)
          .then(standardText => {
            const item = standardText.data.items[0]
            if (item.DeletedYN || !item.ActiveYN) {
              return
            }
            const standardTextObj = {
              StandardTextID: item.StandardTextID,
              Title: item.Title,
              Image: item.Image,
              Url: item.Url,
              Memo: item.Memo
            }
            return commit(ADD_STANDARD_TEXT, standardTextObj)
          })
      })
    }
  },
  mutations: {
    [ADD_STANDARD_TEXT] (state, standardText) {
      state.standardtexts[standardText.StandardTextID] = standardText
    }
  }
}
