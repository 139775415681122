<template>
  <div class="col-12 col-lg-6 d-flex">
    <div class="card card-hover mb-3 mb-lg-4 border-0 bg-light w-100 align-self-stretch">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-2 col-lg-3 col-xl-2 text-center mb-3">
            <b-img :src="person.Image" v-if="person.Image" style="width: 5em; height: auto; border-radius: 500px" slot="aside" :alt="person.Name"/>
            <b-avatar v-else variant="primary" :text="initials" size="4.375rem" class="mr-1 p-0"></b-avatar>
          </div>

          <div class="col">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="mt-0">
                  {{ name }}
                </h4>
                <dl class="mb-0">
                  <dt v-if="person.Teaser">{{ $t("context.particularities") }}</dt>
                  <dd v-if="person.Teaser">{{ person.Teaser }}</dd>
                </dl>
              </div>

              <div class="d-inline-flex flex-column justify-content-end">
                <b-button variant="primary" size="sm" :to="{ name: 'person', params: { student_id: person.PersonID }}" class="float-right mb-1">
                  {{ courseForKids ? $t("actions.view_as_parent") : $t('actions.view') }}
                  <font-awesome-icon icon="angle-right" fixed-width/>
                </b-button>
                <b-button v-if="courseForKids" variant="outline-primary" size="sm" @click="goToChildFriendlyLVS(person.PersonID)" class="float-right" style="text-wrap: nowrap;">
                  {{ $t("actions.view_as") }}&nbsp;{{ person.Name.split(' ')[0] }}
                  <font-awesome-icon icon="angle-right" fixed-width/>
                </b-button>
              </div>
            </div>

            <course-list :insights="person.CustomerInsights" :catch-up-groups="person.CatchUpGroups" :groups="person.ActivityGroup" :person-id="person.PersonID" :attendance="person.Person_SubscriptionAgenda" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CourseList from '@/components/CourseList'
import initialsMixin from '@/util/initialsMixin'

export default {
  name: 'PersonListItem',

  components: { CourseList },

  mixins: [initialsMixin],

  props: {
    person: Object
  },

  computed: {
    ...mapGetters(['config']),

    name () {
      if (this.person.Name) {
        return this.person.Name
      }

      return this.person.PersonID
    },
    previewEnabled () {
      return this.$store.state.preview
    },
    groups () {
      let groups = ''

      if (this.isOnWaitingList) {
        const group = this.person.ActivityGroup.find(group => group.WaitingListYN)
        groups = group.Name
        if (this.person.WaitingListDate) {
          const date = new Date(this.person.WaitingListDate)
          groups += ` <small>(sinds ${date.toLocaleDateString('nl-NL')})</small>`
        }
      } else if (Array.isArray(this.person.ActivityGroup)) {
        groups = this.person.ActivityGroup.map(group => this.activityGroupName(group)).join('<br>')
      }

      return groups
    },
    hasSkills () {
      if (this.person.items.length) {
        return true
      }
      return false
    },
    isOnWaitingList () {
      return this.person.ActivityGroup.reduce((WaitingListYN, group) => WaitingListYN || group.WaitingListYN, false)
    },
    isOnFinishedList () {
      return this.person.ActivityGroup.reduce((FinishedListYN, group) => FinishedListYN || group.FinishedListYN, false)
    },
    skillLevel () {
      if (this.person.SkillCategory && this.person.SkillCategory.Name) {
        return this.person.SkillCategory.Name
      }

      return this.$t('context.level') + ` ${this.person.SkillCategoryID}`
    },
    avgRating () {
      if (!this.person.items || this.person.items.length === 0) {
        return 0
      }
      return this.person.items.reduce((total, skill) => (total + parseFloat(skill.Rating || '0')), 0) / this.person.items.length
    },
    showWaitingListSequence () {
      return this.config.student_tracking.customer.show_waiting_list_sequence
    },
    initials () {
      return this.parseInitials(this.person)
    },
    courseForKids () {
      if (this.previewEnabled) {
        return true
      }

      if (this.person && this.person.ActivityGroup && this.person.ActivityGroup.length > 0) {
        return this.person.ActivityGroup[0].SkillCategory.DisplayType === 'CHILD_FRIENDLY'
      }

      return false
    }
  },
  methods: {
    goToChildFriendlyLVS (personID = null) {
      this.$store.dispatch('persons/selectPerson', (personID))
      this.$router.push({ name: 'childhome', params: { student_id: personID } })
    }
  }
}
</script>
