<template>
  <div class="pb-3 pb-lg-5">
    <div class="container pt-3 pt-lg-5">
      <b-button variant="primary" class="mb-3 mr-2" :to="{ name: 'home' }">
        <font-awesome-icon icon="angle-left" fixed-width/>
        {{ $t("context.home") }}
      </b-button>

      <b-button variant="primary" class="mb-3 mr-2" v-b-modal="_id('modal-group-message')" v-if="!showPlaceholder">
        <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
        {{ $t("context.send_message_single") }}
      </b-button>

      <header class="mb-4 mb-lg-5">
        <div class="row">
          <div class="col" v-if="showPlaceholder">
            <vue-content-loading :speed="1" secondary="#d0d0d0" :height="105" :width="825" style="opacity: 0.35">
              <rect width="400" height="50" x="0" y="10" ry="25" rx="25"></rect>
              <rect width="450" height="20" x="0" y="80" ry="10" rx="10"></rect>
            </vue-content-loading>
          </div>

          <div class="col" v-else>
            <h1 class="display-4 text-contrast">{{ group.Name }}</h1>
            <ul class="list-inline text-contrast lead">
              <li class="list-inline-item">{{ warehouseName }}</li>
              <li class="list-inline-item">&bullet;</li>
              <li class="list-inline-item">{{ date }}</li>
              <li class="list-inline-item">&bullet;</li>
              <li class="list-inline-item">{{ skillCategoryName }}</li>
              <li class="list-inline-item">&bullet;</li>
              <li class="list-inline-item"><span class="badge badge-pill badge-light">{{ students.length }}</span></li>
            </ul>
          </div>
          <div class="col-md-5 col-lg-5 col-xl-4">
            <b-card class="card-hover" no-body v-if="!showPlaceholder">
              <b-card-body>
                <b-card-title>
                  {{ $t("context.instructions") }}
                </b-card-title>

                <div class="form-group">
                  <b-textarea v-model="groupInstructions" rows="2" max-rows="15" :plaintext="!editingInstructions"/>
                </div>

                <div class="d-flex justify-content-end">
                  <b-button variant="outline-secondary" size="sm" class="text-nowrap" @click="resetInstructions" v-if="editingInstructions">
                    <font-awesome-icon icon="xmark"/>
                    {{ $t("actions.cancel") }}
                  </b-button>

                  <b-button variant="success" size="sm" class="text-nowrap ml-2" @click="saveInstructions" :disabled="savingInstructions" v-if="editingInstructions">
                    <font-awesome-icon icon="check"/>
                    {{ $t("actions.save") }}
                  </b-button>

                  <b-button variant="outline-primary" size="sm" class="text-nowrap" @click="editingInstructions = true" v-if="!editingInstructions">
                    <font-awesome-icon icon="pencil"/>
                    {{ $t("actions.edit") }}
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>

        <b-alert v-if="devWarning" variant="warning" :show="true" dismissible><strong>{{ $t("context.take_note") }}</strong> {{ devWarning }}</b-alert>
      </header>
    </div>

    <section v-if="showPlaceholder">
      <div class="container">
        <vue-content-loading :speed="1" secondary="#d0d0d0" :height="65" :width="1250" style="opacity: 0.35">
          <rect width="200" height="34" x="0" y="10" ry="17" rx="17"></rect>
        </vue-content-loading>
      </div>
      <div class="container-fluid">
        <vue-content-loading :speed="1" secondary="#d0d0d0" :height="800" :width="1250" style="opacity: 0.35">
          <rect width="1250" height="600" x="0" y="0" ry="5" rx="5"></rect>
        </vue-content-loading>
      </div>
    </section>

    <section :key="category.SkillCategoryID" v-else v-for="category in allStudents" class="mb-3">
      <div class="container">
        <h2 class="text-contrast mb-3">{{
          category.SkillCategoryID === null ? $t('context.level_unknown') :
            (category.SkillCategory && category.SkillCategory.Name ? category.SkillCategory.Name : `${$t('context.swimlevel')} ${category.SkillCategoryID}`)
          }} <span v-if="category.items.length < students.length"
                   class="badge badge-pill badge-light ml-2">{{ category.items.length }}</span></h2>
      </div>
      <div :class="category.SkillCategoryID === null ? 'container' : 'container-fluid'">
        <student-matrix :students="category.items" :disabled="loading" :_meta="group._meta"/>
      </div>
    </section>

    <message-modal :id="_id('modal-group-message')" :type="TYPE_GROUP" :reference="group" />
  </div>
</template>

<script>
import StudentMatrix from '../components/StudentMatrix'
import { protectedApi, studentTracking } from '@/http'
import MessageModal, { TYPE_GROUP } from '@/components/MessageModal'
import store from '@/store'
import { debounce } from 'lodash'

export default {
  components: { MessageModal, StudentMatrix },
  data () {
    return {
      devWarning: '',
      group: {},
      interval: null,
      tick: false,
      groupMessage: '',
      groupInstructions: '',
      editingInstructions: false,
      savingInstructions: false,
      TYPE_GROUP
    }
  },
  computed: {
    students () {
      return this.group.items || []
    },
    allStudents () {
      return this.students.reduce(this.studentReducer, []).sort((a, b) => {
        if (a.SkillCategoryID === this.group.SkillCategoryID) return -1
        if (b.SkillCategoryID === this.group.SkillCategoryID) return 1
        if (a.SkillCategoryID < b.SkillCategoryID || b.SkillCategoryID === null) return -1
        if (a.SkillCategoryID > b.SkillCategoryID || a.SkillCategoryID === null) return 1
        return 0
      })
    },
    skills () {
      return this.$store.getters.skills
    },
    warehouseName () {
      if (this.group.Warehouse && this.group.Warehouse.Name) {
        return this.group.Warehouse.Name
      }

      const warehouse = this.$store.getters['warehouses/all'].find(w => w.WarehouseID === this.group.WarehouseID)

      if (warehouse && warehouse.Name) {
        return warehouse.Name
      }

      return this.group.WarehouseID
    },
    skillCategoryName () {
      if (this.group.SkillCategory && this.group.SkillCategory.Name) {
        return this.group.SkillCategory.Name
      }

      return this.$t('context.level') + ` ${this.group.SkillCategoryID}`
    },
    date () {
      const date = new Date(this.group.StartDate)
      return `${date.toLocaleDateString('nl-NL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} ${date.toLocaleTimeString('nl-NL', { hour: 'numeric', minute: '2-digit' })}`
    },
    skillLevel () {
      if (this.group.SkillCategoryID) {
        return this.$t('context.level') + ` ${this.group.SkillCategoryID}`
      }

      return ''
    },
    showPlaceholder () {
      return this.loading
    },
    loading () {
      return Object.values(this.group).length === 0
    }
  },
  methods: {
    studentReducer (returnValue, student) {
      // Look for existing SkillCategoryID collection for this student (will return -1 if not found)
      let index = returnValue.findIndex(skillGroup => skillGroup.SkillCategoryID === student.SkillCategoryID)

      if (index < 0) {
        returnValue.push({
          SkillCategoryID: student.SkillCategoryID || null,
          SkillCategory: student.SkillCategory || {},
          items: [student]
        })
      } else {
        returnValue[index].items.push(student)
      }
      return returnValue
    },
    fetchGroup: debounce(function () {
      studentTracking.get(this.$route.path)
        .then(response => {
          this.group = response.data
          this.resetInstructions()
        })
    }, 100, { leading: true, trailing: false }),
    resetInstructions () {
      this.editingInstructions = false
      this.groupInstructions = this.$options.filters.stripTags(this.group.Description || '')
    },
    saveInstructions () {
      this.savingInstructions = true
      protectedApi.put('/activity-groups/' + this.group.ActivityGroupID, {
        Description: this.groupInstructions
      })
        .then(() => { this.editingInstructions = false })
        .catch(this.showErrorToast)
        .finally(() => { this.savingInstructions = false })
    },
    onMounted () {
      if (Object.values(this.group).length === 0) {
        this.fetchGroup()
      }
    }
  },
  watch: {
    group (group) {
      if (Object.values(group).length === 0) {
        return
      }

      if (group.EmployeeID && !this.$store.getters['groups/activeView']) {
        this.$store.dispatch('groups/setActiveEmployee', { EmployeeID: group.EmployeeID })
      }
      if (this.$store.getters['groups/activeView']) {
        this.$store.dispatch('groups/setViewDate', new Date(group.StartDate))
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (store.state.groups.needRefresh.includes(to.params.agenda_id) || from.name === 'skill') {
        store.dispatch('groups/markGroupAsRefreshed', { group: to.params.agenda_id })
        vm.group = {}
      }

      vm.onMounted()
    })
  }
}
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.15s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
