<template>
  <div class="container py-3 py-md-5">
    <div class="row justify-content-center">
      <div class="col col-sm-10 col-md-8 col-lg-7 col-xl-5">
        <div class="card bg-light card-hover">
          <div class="card-body text-center px-4 px-sm-5 py-4 py-sm-5">
            <h1 class="card-title">Dataduiker LVS</h1>

            <p v-if="loginType === AUTH_TYPE_CUST">{{ $t("context.log_in_to_view_progress") }}</p>

            <div v-if="loginType === AUTH_TYPE_CUST">
              <b-button :disabled="submitted" @click="redirectLogin" variant="outline-primary" type="submit" class="btn-sign-in mb-2">
                <img alt="icon" :src="iconUrl" class="mr-1 logo"/>
                {{ $t("context.log_in_using") }} <span class="text-nowrap font-weight-bold">{{ $store.state.info.siteName || 'Mijn Dataduiker' }}</span>
              </b-button>
            </div>
            <b-form @submit.prevent="onSubmit" v-else>
              <b-form-group size="lg">
                <b-input size="lg" :disabled="loggingIn" :state="usernameValid" name="username" v-model="username"
                         :placeholder="loginType === AUTH_TYPE_CUST ? 'E-mailadres' : $t('context.username')"></b-input>
              </b-form-group>

              <b-form-group size="lg">
                <b-input size="lg" :disabled="loggingIn" :state="passwordValid" type="password" v-model="password"
                         :placeholder="`${$t('context.password')}`"></b-input>
              </b-form-group>

              <b-form-group>
                <b-button size="lg" block :disabled="loggingIn" type="submit">
                  {{ $t("context.log_in") }}
                  <font-awesome-icon :icon="loggingIn ? ['fad', 'spinner-third'] : ['fas', 'lock']" fixed-width :spin="loggingIn" />
                </b-button>
                <b-form-text class="mt-2" text-variant="danger" v-if="error">{{ error }}</b-form-text>
              </b-form-group>

              <p v-if="loginType === 'Employee'">
                <a :href="newsletterUrl" target="_blank">
                  {{ $t("context.subscribe_newsletter") }}<font-awesome-icon :icon="['fad', 'up-right-from-square']" class="ml-2" fixed-width/>
                </a>
              </p>
            </b-form>

            <hr>

            <p class="mb-0 text-muted">
              <a v-if="loginType === AUTH_TYPE_CUST" class="text-reset" href="javascript:void(0)" @click.prevent="loginType = AUTH_TYPE_USER">
                {{ $t("context.login_as_employee") }}<font-awesome-icon :icon="['fas', 'water-ladder']" class="ml-1" fixed-width/>
              </a>

              <a v-else class="text-reset" href="javascript:void(0)" @click.prevent="loginType = AUTH_TYPE_CUST">
                <font-awesome-icon :icon="['fas', 'arrow-left-long']" class="mr-1" fixed-width/>{{ $t("context.login_as_parent") }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Logo from '@/assets/logo.svg?inline'
import { AUTH_TYPE_CUST, AUTH_TYPE_USER } from '@/store/auth'
import { publicApi } from '@/http'

export default {
  name: 'Login',
  components: {
    // Logo: () => require(`@/build/${this.$canonicalUser}/svg/icon.svg?inline`)
  },
  data () {
    return {
      username: '',
      password: '',
      submitted: false,
      loginTypes: [
        { text: this.$t('context.parent_caregiver'), value: AUTH_TYPE_CUST },
        { text: this.$t('context.employee'), value: AUTH_TYPE_USER }
      ],
      AUTH_TYPE_CUST,
      AUTH_TYPE_USER
    }
  },
  computed: {
    loginType: {
      get () {
        return this.$store.getters['auth/authType']
      },
      set (type) {
        this.$store.dispatch('auth/setAuthType', type)
      }
    },
    loggingIn () {
      return this.submitted && (this.$store.state.auth.status.loggingIn || this.$store.state.auth.status.loggedIn)
    },
    error () {
      return this.$store.state.auth.status.failure
    },
    fqUsername () {
      if (this.loginType === AUTH_TYPE_CUST) {
        return `${AUTH_TYPE_CUST}:${this.username}`
      }

      return this.username
    },
    usernameValid () {
      if (this.submitted && this.username.length < 3) {
        return false
      }

      return null
    },
    passwordValid () {
      if (this.submitted && this.password.length < 3) {
        return false
      }

      return null
    },
    newsletterUrl () {
      const url = new URL('https://dataduiker.nl/nieuwsbrief/')
      url.searchParams.set('utm_source', this.$canonicalUser)
      url.searchParams.set('utm_medium', 'lvs')
      url.searchParams.set('utm_campaign', 'employee_signup')

      return url.toString()
    },
    iconUrl () {
      return require(`@/build/${this.$canonicalUser}/svg/icon.svg`)
    }
  },
  methods: {
    redirectLogin () {
      this.submitted = true
      publicApi.get('/auth/url', {
        params: {
          referer: window.location.origin + window.location.pathname + '#/'
        }
      })
        .then(({ data }) => {
          if (!data.url) {
            throw new Error(this.$t('errors.connectionFailed', { siteName: this.$store.state.info.siteName || 'Mijn Dataduiker' }))
          }
          window.location = data.url
        })
        .catch(e => {
          this.submitted = false
          this.showErrorToast(e)
        })
    },
    onSubmit () {
      this.submitted = true

      if (this.usernameValid !== false && this.passwordValid !== false) {
        this.$store.dispatch('auth/login', { username: this.fqUsername, password: this.password })
      } else {
        // this.submitted = false
      }
    }
  },
  watch: {
    error (error) {
      if (error) {
        this.submitted = false
        document.cookie = `jwt=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
      }
    }
  },
  created () {
    this.$store.dispatch('auth/logout')
  }
}
</script>

<style scoped lang="scss">
.btn-sign-in {
  font-weight: normal;
  .logo {
    height: 1.5em;
    max-width: 2.5em;
    vertical-align: bottom;
    transition: filter ease-in-out 0.15s;
  }
  &:hover, &:active {
    &:not(:disabled) {
      .logo {
        filter: saturate(0) brightness(10); // force white
      }
    }
  }
}
</style>
