<template>
  <div>
    <router-link class="text-reset" :to="{name: 'student', params: {student_id: id}}">{{name}}</router-link>
  </div>
</template>

<script>
export default {
  name: 'StudentLink',
  props: [
    'id', 'name', 'placement'
  ]
}
</script>

<style scoped>

</style>
