import { render, staticRenderFns } from "./SkillLink.vue?vue&type=template&id=4dc0f1dc&scoped=true&"
import script from "./SkillLink.vue?vue&type=script&lang=js&"
export * from "./SkillLink.vue?vue&type=script&lang=js&"
import style0 from "./SkillLink.vue?vue&type=style&index=0&id=4dc0f1dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dc0f1dc",
  null
  
)

export default component.exports