import axios from 'axios'
import store from '@/store'

axios.defaults.headers = { 'Content-Type': 'application/json' }

const requestFulfilled = config => config

const requestRejected = error => Promise.reject(error)

const responseFulfilled = response => response

const responseRejectedFactory = http => {
  return (error) => {
    if (error.response && error.response.status === 401) {
      return store.dispatch('auth/login', { redirect: false, fetchUser: false })
        .then(() => http.request(error.config))
    }
    return Promise.reject(error)
  }
}

const publicApi = axios.create({ baseURL: '/api' })

const protectedApi = axios.create({ baseURL: '/api' })
protectedApi.interceptors.request.use(requestFulfilled, requestRejected)
protectedApi.interceptors.response.use(responseFulfilled, responseRejectedFactory(protectedApi))

const studentTracking = axios.create({ baseURL: '/api/student-tracking' })
studentTracking.interceptors.request.use(requestFulfilled, requestRejected)
studentTracking.interceptors.response.use(responseFulfilled, responseRejectedFactory(studentTracking))

export {
  publicApi,
  protectedApi,
  studentTracking
}
