<template>
  <div class="mb-5">
    <StudentTransferModal
      :showModal="showModalTransfer"
      :multipleStudents="multipleStudents"
      @transferModalClosed="showModalTransfer = false"
      @markGroupForRefresh="markGroupForRefresh"
    />

    <b-dropdown :text="$t('common.actions')" class="mb-3" variant="secondary" size="md" :disabled="!actionsPossible">
      <b-dropdown-header id="dropdown-header-label">
        {{ $t("context.presence") }}
      </b-dropdown-header>
      <b-dropdown-item @click="togglePresence(true)" variant="success" :disabled="isFuture">
        <font-awesome-icon icon="user-plus" fixed-width />
      {{ selectionStatus === 'all' ? $t("context.everybody_present") : $t("context.selection_present") }}
      </b-dropdown-item>

      <b-dropdown-item @click="togglePresence(false)" variant="danger" :disabled="isFuture">
        <font-awesome-icon icon="user-slash" fixed-width />
        {{ selectionStatus === 'all' ? $t("context.everybody_absent") : $t("context.selection_absent") }}
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-header id="dropdown-header-label">
        {{ $t("context.edit_group") }}
      </b-dropdown-header>

      <b-dropdown-item @click="showModalTransfer = true">
        <font-awesome-icon icon="angles-right" fixed-width />
        {{ selectionStatus === 'all' ? $t("context.everybody_move") : $t("context.selection_move") }}
      </b-dropdown-item>
    </b-dropdown>

    <div class="wrapper rounded table-hover student-matrix-wrapper">
      <table class="table table-hover bg-white matrix-3d">
        <thead ref="thead" class="bg-light">
          <tr class="table-light bg-light">
            <th style="width:1%">
              <b-form-checkbox v-model="selectAll" @change="toggleAll" :indeterminate.sync="indeterminateSelection" />
            </th>

            <th style="width:150px">
              <!-- <small>{{ selectedIndexes.length && selectedIndexes.length === students.length ? 'Deselecteer alle' : 'Selecteer alle' }}</small> -->
            </th>

            <th class="text-center" :key="i" v-for="(skill, i) in skills">
              <skill-link :skill="skill" placement="bottom" />
            </th>
          </tr>
        </thead>
        <tbody ref="tbody">
          <StudentMatrixRow
            v-for="student in sortedStudents"
            :student="student"
            :_meta="_meta"
            :ref="`${refPrefix}${student.PersonID}`"
            :disabled="disabled"
            :key="student.PersonID"
            :selectedIndexes="selectedIndexes"
            @toggleSelection="toggleSelection"
          />
        </tbody>
      </table>
    </div>
    <small class="text-contrast present-legenda">* <em><strong>{{ $t("context.present") }}</strong> {{
        $t("context.current_level") }} / <strong>{{ $t("context.absent") }}</strong> {{ $t("context.current_level")
        }}</em></small>
  </div>
</template>

<script>
import StudentMatrixRow from './StudentMatrixRow'
import SkillLink from './SkillLink'
import StudentTransferModal from './StudentTransferModal.vue'
import { mapActions } from 'vuex'

const refPrefix = 'student-'

export default {
  name: 'StudentMatrix',

  components: {
    SkillLink,
    StudentMatrixRow,
    StudentTransferModal
  },

  props: {
    students: Array,
    disabled: Boolean,
    _meta: Object
  },

  data () {
    return {
      refPrefix,
      selectedIndexes: [],
      selectAll: false,
      indeterminateSelection: false,
      showModalTransfer: false
    }
  },

  computed: {
    currentGroup () {
      return this.$store.getters['groups/all'].find(group => {
        return group.SubscriptionAgendaID === this.$route.params.agenda_id
      }) || {}
    },
    skills () {
      return this.students.length > 0 ? this.students[0].items : []
    },
    actionsPossible () {
      return !this.isFuture || this.students.length > 0
    },
    isFuture () {
      return (new Date(this.currentGroup.StartDate)).getTime() - (new Date()).getTime() > 1000 * 3600 * 24
    },
    sortedStudents () {
      return this.studentsSorted
    },
    selectionStatus () {
      switch (this.selectedIndexes.length) {
        case 0:
        case this.students.length:
          return 'all'
        case 1:
          return 'single' // currently not used but could be useful in the future
        default:
          return 'multiple'
      }
    },
    multipleStudents () {
      // No selected? All selected.
      if (!this.selectedIndexes.length) {
        return this.students
      }

      return this.students.filter(student => this.selectedIndexes.includes(student.PersonID))
    }
  },

  beforeMount () {
    this.studentsSorted = this.students.sort((a, b) => a.Name.localeCompare(b.Name))
  },

  methods: {
    ...mapActions('groups', ['markGroupForRefresh']),

    togglePresence (present) {
      const promisesList = []

      this.multipleStudents.forEach((student) => {
        promisesList.push(this.$refs[`${refPrefix}${student.PersonID}`][0].togglePresence(new Event('toggle'), present))
      })

      Promise.all(promisesList).then((student) => {
        if (this.selectedIndexes.length === 1) {
          this.showSuccessToast(this.$t(`context.student_${present ? 'present' : 'absent'}`, { name: student[0].Name }))
        } else {
          if (this.selectedIndexes.length === this.students.length || this.selectedIndexes.length === 0) {
            this.showSuccessToast(this.$t(present ? 'context.everybody_present' : 'context.everybody_absent'))
          } else {
            this.showSuccessToast(this.$t(present ? 'context.selection_present' : 'context.selection_absent'))
          }
        }

        this.clearSelection()
      })
    },

    toggleSelection (index) {
      if (this.selectedIndexes.includes(index)) {
        this.selectedIndexes = this.selectedIndexes.filter(i => i !== index)
      } else {
        this.selectedIndexes.push(index)
      }
    },

    // Toggle all students
    toggleAll () {
      if (this.selectedIndexes.length === 0 || this.selectedIndexes.length !== this.students.length) {
        this.selectedIndexes = this.students.map(student => student.PersonID)
      } else {
        this.clearSelection()
      }
    },

    clearSelection () {
      this.selectedIndexes = []
      this.selectAll = false
    }
  },

  watch: {
    selectedIndexes (newVal) {
      this.selectAll = newVal.length === this.students.length
    },

    '$route' () {
      this.clearSelection() // reset on route change
    },

    selectionStatus (newVal) {
      if (newVal === 'all') {
        this.indeterminateSelection = this.selectedIndexes.length === this.students.length ? false : this.selectedIndexes.length > 0
        this.selectAll = this.selectedIndexes.length === this.students.length
      } else {
        this.indeterminateSelection = (newVal === 'multiple' || newVal === 'single')
        this.selectAll = false
      }
    }
  }
}
</script>
