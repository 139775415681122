<template>
  <b-modal v-bind="$attrs"
           :ref="_id('message_modal')"
           size="lg" ok-variant="success" ok-title="Versturen" cancel-variant="outline-dark" cancel-title="Annuleren"
           @ok="send" @cancel="onCancel" @close="onCancel"
           :ok-disabled="message.length < 5"
           no-close-on-backdrop no-close-on-esc
  >
    <span slot="modal-title">
      <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
      {{ title }}
    </span>

    <b-form-group :label="`${this.title}:`"
                  :description="`${this.$t('context.message_will_be_send_to_message')}`">
      <b-textarea v-model="message" rows="10" :placeholder="`${this.$t('context.dear_parents')}`" required></b-textarea>
    </b-form-group>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { studentTracking } from '@/http'

export const TYPE_DEFAULT = 'default'
export const TYPE_GROUP = 'group'
export const TYPE_DATE = 'date'
export const TYPE_WAREHOUSE = 'warehouse'
export const TYPE_EMPLOYEE = 'employee'

export const TYPES = [
  TYPE_DEFAULT,
  TYPE_GROUP,
  TYPE_DATE,
  TYPE_WAREHOUSE,
  TYPE_EMPLOYEE
]

export default {
  name: 'MessageModal',
  props: {
    type: {
      type: String,
      default: TYPE_DEFAULT,
      validator: a => TYPES.includes(a)
    },
    reference: {
      type: [String, Date, Object]
    }
  },
  data () {
    return {
      message: ''
    }
  },
  computed: {
    ...mapGetters('groups', [
      'viewDate',
      'employeeId',
      'activeEmployee'
    ]),
    title () {
      switch (this.type) {
        case TYPE_DATE:
          return this.$t('context.send_message_to_all_groups_on') + ` ${moment(this.viewDate).format('ll')}`
        case TYPE_WAREHOUSE:
          return this.$t('context.send_message_to_all_present_in') + ` ${this.reference.Name} op ${moment(this.viewDate).format('ll')}`
        case TYPE_EMPLOYEE:
          return this.$t('context.send_message_to_all_groups_of') + ` ${this.activeEmployee.Name}`
        case TYPE_GROUP:
          return this.$t('context.send_message_to_group') + ` ${this.reference.Name}`
        case TYPE_DEFAULT:
          return this.$t('context.send_message_to_all_my_groups')
        default:
          return this.$t('context.send_message')
      }
    }
  },
  methods: {
    send () {
      let promise

      switch (this.type) {
        case TYPE_WAREHOUSE:
        case TYPE_DATE:
        case TYPE_EMPLOYEE:
        case TYPE_DEFAULT:
          promise = studentTracking
            .post('/subscription-agenda/reviews', {
              Review: this.message,
              ReviewTypeID: 2,
              EmployeeID: this.type !== TYPE_DATE && this.type !== TYPE_WAREHOUSE ? this.employeeId : undefined,
              WarehouseID: this.reference?.WarehouseID,
              StartDate: this.viewDate ? moment(this.viewDate).format('YYYY-MM-DD HH:mm:ss') : undefined
            })
            .catch(error => this.showErrorToast(error))
          break
        case TYPE_GROUP:
          promise = studentTracking
            .post(this.$route.path + '/reviews', {
              Review: this.message,
              ReviewTypeID: 2
            })
            .catch(error => this.showErrorToast(error))
          break
        default:
          break
      }

      if (promise) {
        promise
          .then(() => {
            this.reset()
            this.$bvToast.toast(this.$t('context.message_sent_succesfully'), {
              title: this.$t('context.message_send'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
              appendToast: true
            })
          })
          .catch(error => this.showErrorToast(error))
      }
    },
    reset () {
      this.message = ''
    },
    show () {
      this.$refs[this._id('message_modal')].show()
    },
    onCancel (event) {
      if (this.message.length >= 5 && !window.confirm(this.$t('context.message_send_cancel_confirmation'))) {
        event.preventDefault()
        return
      }

      this.reset()
    }
  }
}
</script>

<style scoped>

</style>
