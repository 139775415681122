<template>
  <div class="loader-wrapper d-flex flex-grow-1 h-100 w-100 position-fixed justify-content-center align-items-center">
    <div style="width: 50%; max-width: 200px;">
      <b-aspect aspect="1">
        <div class="ripple-anim">
          <div></div>
          <div></div>
        </div>
      </b-aspect>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader'
}
</script>

<style scoped>
.loader-wrapper {
  top: 0;
  left: 0;
}

.ripple-anim {
  position: relative;
  width: 100%;
  height: 100%;
}

.ripple-anim div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: ripple-anim 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple-anim div:nth-child(2) {
  animation-delay: -1s;
}

@keyframes ripple-anim {
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
