var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm._id('modal-transfer'),"ok-variant":"success","ok-title":_vm.$t('context.transfer'),"cancel-variant":"outline-dark","cancel-title":_vm.$t('actions.cancel'),"ok-disabled":_vm.transferToGroup === null},on:{"ok":_vm.startTransfer,"show":_vm.fetchSimilarGroups,"hidden":function($event){return _vm.$emit('transferModalClosed')}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_c('font-awesome-icon',{attrs:{"icon":"angles-right","fixed-width":""}}),_vm._v(_vm._s(_vm.$t('context.transfer_student', { count: _vm.student || _vm.multipleStudents.length === 1 ? 1 : 2 })))],1),_c('b-form-group',{attrs:{"label":_vm.$t('context.transfer_group_student', { count: _vm.student || _vm.multipleStudents.length === 1 ? 1 : 2 }),"description":!_vm.$store.getters['auth/isAdmin'] ? _vm.$t('context.transfer_group_student_error') : ''}},[_c('b-select',{attrs:{"disabled":_vm.transferGroupOptions.length === 0,"options":_vm.transferGroupOptions},model:{value:(_vm.transferToGroup),callback:function ($$v) {_vm.transferToGroup=$$v},expression:"transferToGroup"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('context.transfer_which_date')}},[_c('b-form-datepicker',_vm._b({attrs:{"locale":"nl-NL","hide-header":"","min":new Date().toISOString().split('T')[0],"max":_vm.maxDate,"required":"","today-button":"","disabled":_vm.transferGroupOptions.length === 0},model:{value:(_vm.transferToGroupStartDate),callback:function ($$v) {_vm.transferToGroupStartDate=$$v},expression:"transferToGroupStartDate"}},'b-form-datepicker',{
          labelPrevDecade: _vm.$t('datepicker.decade_back'),
          labelPrevYear: _vm.$t('datepicker.year_back'),
          labelPrevMonth: _vm.$t('datepicker.month_back'),
          labelCurrentMonth: _vm.$t('datepicker.this_month'),
          labelNextMonth: _vm.$t('datepicker.month_ahead'),
          labelNextYear: _vm.$t('datepicker.year_ahead'),
          labelNextDecade: _vm.$t('datepicker.decade_ahead'),
          labelToday: _vm.$t('common.today'),
          labelTodayButton: _vm.$t('datepicker.select_today'),
          labelSelected: _vm.$t('datepicker.chosen_date'),
          labelNoDateSelected: _vm.$t('datepicker.no_date_chosen'),
          labelCalendar: _vm.$t('common.calendar'),
          labelNav: _vm.$t('common.navigation'),
          labelHelp: _vm.$t('datepicker.use_arrows_to_navigate')
      },false))],1),_c('b-checkbox',{model:{value:(_vm.adoptSkillCategoryFromGroup),callback:function ($$v) {_vm.adoptSkillCategoryFromGroup=$$v},expression:"adoptSkillCategoryFromGroup"}},[_vm._v(_vm._s(_vm.$t('context.select_skill_from_new_group')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }