import { has } from 'lodash'

export default {
  computed: {
    selectedPersonID () {
      return this.$store.state.persons.selectedPersonID
    }
  },

  methods: {
    parseInitials (person) {
      if (person) {
        if (has(person, 'Name2')) {
          let initials = person.Name.charAt(0)

          if (person.Name2) {
            initials += person.Name2.charAt(0)
          }

          // Replace (delete) all non-alphanumeric characters
          initials = initials.replace(/[^\w\s]/gi, '')

          return initials
        } else {
          return person.Name ? person.Name.split(' ').map(name => name.charAt(0)).join('') : false
        }
      }

      return false
    }
  }
}
