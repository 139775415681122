import { studentTracking } from '@/http'

export const SET_EMPLOYEES = 'SET_EMPLOYEES'

export default {
  namespaced: true,
  state: {
    employees: []
  },
  actions: {
    fetch ({ commit, rootGetters }) {
      if (rootGetters['auth/isAdmin']) {
        return studentTracking.get('employees', {
          params: {
            limit: 100
          }
        }).then(user => {
          return commit(SET_EMPLOYEES, user.data.items || [])
        })
      }
    }
  },
  mutations: {
    [SET_EMPLOYEES] (state, employees) {
      state.employees = employees
    }
  },
  getters: {
    all: state => state.employees.sort((a, b) => {
      if (a.Name < b.Name) {
        return -1
      }
      if (a.Name > b.Name) {
        return 1
      }
      return 0
    })
  }
}
