import store from '@/store'

const locale = store.getters.getLocale || 'nl-NL'

const whitelistOptions = (options, allowed) => Object.keys(options)
  .filter(key => allowed.includes(key))
  .reduce((obj, key) => {
    obj[key] = options[key]
    return obj
  }, {})

const formatDate = (date, options = {}) => {
  if (!date) return date

  if (typeof date === 'string') {
    date = new Date(date)
  }

  return date.toLocaleDateString(locale, whitelistOptions(options, [
    'dateStyle',
    'weekday',
    'year',
    'month',
    'day'
  ]))
}

export default formatDate
