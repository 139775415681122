import { publicApi } from '@/http'

export const ADD_BANNER = 'ADD_BANNER'

export default {
  namespaced: true,
  state: {
    banners: {}
  },
  actions: {
    fetch ({ commit }) {
      const bannerSpotArr = [
        'LVSCTABANNER01'
      ]

      bannerSpotArr.forEach(bannerSpotID => {
        publicApi.get(`banners/${bannerSpotID}`)
          .then(({ data }) => {
            if ((data.items || []).length === 0) {
              return
            }
            const item = data.items[0]
            if (item.DeletedYN || !item.ActiveYN || !item.Image) {
              return
            }
            const bannerObj = {
              BannerSpotID: item.BannerSpotID,
              BannerName: item.BannerName,
              Image: item.Image,
              Url: item.Url
            }
            return commit(ADD_BANNER, bannerObj)
          })
          // Prevent uncaught rejection error (most likely 404), but do not log
          .catch(() => {})
      })
    }
  },
  mutations: {
    [ADD_BANNER] (state, banner) {
      state.banners[banner.BannerSpotID] = banner
    }
  }
}
