import i18next from 'i18next'
import store from '@/store'
import resources from './translation.json'
import Vue from 'vue'
import I18NextVue from 'i18next-vue'

i18next.init({
  lng: store.state.locale,
  supportedLngs: ['nl', 'en', 'de'],
  fallbackLng: 'nl',
  resources,
  returnEmptyString: false,
  cleanCode: true,
  nonExplicitSupportedLngs: true,
  debug: process.env.NODE_ENV === 'development'
})

Vue.use(I18NextVue, { i18next })
