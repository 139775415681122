<template>

  <div class="accordion mt-3 col-12" role="tablist">
    <template>

    </template>
    <div v-for="group in this.renderGroups" :key="group.UID">
      <p class="lead mt-4 text-contrast">{{ group.WarehouseName }}, {{ group.GroupName }}
        <b-badge class="text-danger" variant="light" v-if="!group.ValidAccessYN && group.ProgressTrackingYN">
          <font-awesome-icon icon="ban" fixed-width/>
          {{ $t("context.lesson_card") }}
        </b-badge>
        <b-badge  class="text-success" variant="light" v-if="group.ReadyForFinishedActivityGroupYN">
          <font-awesome-icon :icon="['fad', 'person-swimming']" fixed-width/>
          {{ $t("context.finish_course_allowed") }}
        </b-badge>
      </p>
      <table class="table table-bordered table-hover bg-white rounded overflow-hidden" v-if="group.ProgressTrackingYN && group.SkillCategoryName && group.PersonSkill.length">
        <thead class="table-light bg-light" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
          <th>{{ group.SkillMaxRating }}</th>
          <th role="tab" header-tag="header" v-b-toggle="'accordion-' + group.UID">
            <span class="when-opened float-right">
              <font-awesome-icon icon="chevron-down"/>
            </span>
            <span class="when-closed float-right">
              <font-awesome-icon icon="chevron-right"/>
            </span>
          </th>
        </thead>
        <b-collapse :id="'accordion-' + group.UID" visible :accordion="'my-accordion-' + group.PersonID" role="tabpanel" class="d-contents">
          <tbody>
            <tr>
              <th><strong>{{ $t("context.skill") }}</strong></th>
              <th class="col-5"><strong>{{ $t("context.skill_control") }}</strong></th>
            </tr>
            <tr :key="item.SkillID" v-for="(item) in group.PersonSkill">
              <td style="vertical-align: middle">
                <skill-link :skill="item" placement="right"/>
              </td>
              <td><skill-rating
                :value="item.Rating"
                :skill-id="item.SkillID"
                :max-rating="item.MaxRating"
                @change="updateSkill(item.SkillID, $event)"
              /></td>
            </tr>
          </tbody>
        </b-collapse>
        <tfoot>
          <tr class="table-light bg-light">
            <th>{{ $t("context.total_progress") }}</th>
            <th class="col-5"><skill-rating :max-rating="group.Skill.MaxRating" :value="group.Skill.TotalRating"></skill-rating></th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

</template>

<script>
import SkillRating from '@/components/SkillRating'
import SkillLink from '@/components/SkillLink'

export default {
  name: 'CourseList',
  components: { SkillRating, SkillLink },
  props: {
    insights: Array,
    groups: Array,
    personId: String,
    attendance: Array
  },
  computed: {
    renderGroups () {
      let renderGroups = []
      this.insights.forEach(group => {
        renderGroups.push({
          'PersonID': this.personId,
          'UID': this.getUniqueIdentifier(group),
          'GroupName': this.getNameOfGroup(group),
          'EmployeeName': this.getEmployeeName(group),
          'Skill': group.Skill,
          'PersonSkill': group.PersonSkill,
          'ProgressTrackingYN': !this.getProgressTrackingYN(group),
          'SkillCategoryName': this.getSkillCategoryNameForGroup(group),
          'SkillMaxRating': this.getSkillCategoryNameForGroup(group),
          'WarehouseName': this.getWarehouseNameOfGroup(group),
          'ValidAccessYN': group.ValidAccessYN,
          'ReadyForFinishedActivityGroupYN': group.ReadyForFinishedActivityGroupYN
        })
      })
      return renderGroups
    }
  },
  methods: {
    getUniqueIdentifier (group) {
      return `${this.personId}_${group.ActivityGroupID}`
    },
    getNameOfGroup (group) {
      return group.ActivityGroupName
    },
    getEmployeeName (group) {
      return group.EmployeeName
    },
    getSkillCategoryNameForGroup (group) {
      return group.Skill.SkillCategoryName
    },
    getWarehouseNameOfGroup (group) {
      return group.WarehouseName
    },
    getAvailabilityStringsForGroup (group) {
      return group.Availability.map(e => {
        let a = {
          'string': '',
          'AvailableYN': e.AvailableYN
        }
        a.string = this.dayOfWeekFromAvailability(e)
        return a
      })
    },
    getPlannedMomentsForGroup (group) {
      return group.PlannedMoments.map(e => {
        return {
          'id': e.Person_SubscriptionAgendaID,
          'date': e.StartDateFormatted,
          'isCancelled': e.CancelYN
        }
      })
    },
    getLastMomentForGroup (group) {
      if (Object.is(group.MostRecentlyPresentMoment, null)) {
        return ''
      }
      return group.MostRecentlyPresentMoment.EndDateFormatted
    },
    getProgressTrackingYN (group) {
      return group.WaitingListYN || group.FinishedListYN
    },
    dayOfWeekFromAvailability (availability) {
      if (!availability) {
        return this.$t('context.time_unknown')
      }

      // 1970-01-01 is a Sunday (0), so add DayOfWeek to get the correct day name
      try {
        const dateStart = new Date(1970, 1, 1, ...availability.TimeStart.split(':').map(str => parseInt(str)))
        dateStart.setDate(dateStart.getDate() + parseInt(availability.DayOfWeek))
        const dateEnd = new Date(1970, 1, 1, ...availability.TimeEnd.split(':').map(str => parseInt(str)))
        dateEnd.setDate(dateEnd.getDate() + parseInt(availability.DayOfWeek))

        return dateStart.toLocaleString('nl-NL', {
          weekday: 'long',
          hour: 'numeric',
          minute: '2-digit'
        }) + ' - ' +
          dateEnd.toLocaleString('nl-NL', {
            hour: 'numeric',
            minute: '2-digit'
          })
      } catch (e) {
        return this.$t('context.time_unknown')
      }
    }
  }
}
</script>

<style type="text/scss" scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
li.iscancelled {
  text-decoration: line-through;
}
.d-contents {
  display: contents;
}
.collapse {
  opacity: 0;
}
.collapse .show {
  opacity: 1;
}
</style>
