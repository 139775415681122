<template>
  <b-modal
    id="studentSearchModal"
    title="Cursist zoeken"
    :ref="_id('search_modal')"
  size="lg">
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-dark"
          class="float-right"
          @click="close"
        >
          {{ $t("actions.close") }}
        </b-button>
      </div>
    </template>
    <b-form-group>
      <div class="form-group">
        <b-input autofocus v-model="searchString" debounce="600" type="search" ref="searchInput" ></b-input>
        <small id="emailHelp" class="form-text text-muted">{{ $t("context.search_criteria") }}</small>
      </div>
    </b-form-group>
    <student-list-item :key="i" v-for="(student, i) in searchedPersons" v-bind:student="student" @clicked="close"></student-list-item>
    <div class="d-flex justify-content-center m-3">
      <font-awesome-icon class="ml-3" v-if="$store.state.persons.loading" spin icon="rotate" size="3x"/>
    </div>
    <p></p>
  </b-modal>
</template>

<script>

import StudentListItem from './StudentListItem'

export default {
  name: 'SearchStudentModal',
  components: { StudentListItem },
  props: {
  },
  data () {
    return {
      searchString: '',
      refPrefix: ''
    }
  },
  computed: {
    searchedPersons () {
      return this.$store.getters['persons/searchResults']
    }
  },
  watch: {
    searchString (newValue) {
      if (newValue !== '' && newValue.length >= 3) {
        this.search()
      }
    }
  },
  methods: {
    show () {
      this.$refs[this._id('search_modal')].show()
    },
    close () {
      this.$refs[this._id('search_modal')].hide()
    },
    search () {
      this.$store.dispatch('persons/searchStudents', { searchString: this.searchString })
    }
  }
}
</script>
